// eslint-disable-next-line import/prefer-default-export
export const theme = {
  primary: '#f18500',
  primaryHover: '#be6900',
  secondary: '#000',
  disabled: '#ccc',
  gray100: '#F1F1F1',

  letterSpacing: '-0.36px',
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },

  breakpoints: {
    xsm: '460px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1280px',
  },

  gutter: '32',

  containers: {
    xl: '1256px',
  },

  zIndex: {
    nav: 8,
    navContent: 10,
  },

  boxShadow: {
    default: '0 8px 24px rgba(0, 0, 0, 0.12)',
  },

  navbarBreakpoint: 'xl',
  btnTransition: 'color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease',
};
