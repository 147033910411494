import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  fetchView as fetchViewAction,
  changeFlashing as changeFlashingAction,
  changeRoffingType as changeRoffingTypeAction,
} from '../../store/views/flashing/actions';

import { Section, Container, Row, LoaderView } from '../../layout';
import Select from '../../components/Select/Select';
import Radio from '../../components/Radio/Radio';
import Title from '../../components/Title/Title';
import { FlashingBox, RoofingTypeCol } from './Flashing.styled';

class Flashing extends Component {
  componentDidMount() {
    if (!this.props.viewData.fetched) {
      const pageId = this.props.match.params.id;
      this.props.fetchView(pageId, this.props.appType);
    }
  }

  handleFlashingChange = event => {
    this.props.changeFlashing(event.target.value);
  };

  getCheckedId = list => {
    const checked = list.find(item => item.checked);
    return checked ? checked.id : 'none';
  };

  render() {
    const { viewLoading, translations } = this.props;
    const {
      flashings,
      roofingTypes,
      flashingsDisabled,
      roofingTypesDisabled,
    } = this.props.viewData;

    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <>
            {flashings && flashings.length > 1 && (
              <>
                <Section>
                  <Container>
                    <Title as="h2" margin>
                      {translations.flashingCodes}
                    </Title>
                    <FlashingBox>
                      <Select
                        options={flashings}
                        value={this.getCheckedId(flashings)}
                        disabled={flashingsDisabled}
                        onChange={this.handleFlashingChange}
                      />
                    </FlashingBox>
                  </Container>
                </Section>
                <Section>
                  <Container>
                    <Title as="p" primary>
                      {translations.or}
                    </Title>
                  </Container>
                </Section>
              </>
            )}
            <Section>
              <Container>
                <Title as="h2" margin>
                  {translations.roofingType}
                </Title>
                <Row center>
                  {roofingTypes.map(item => (
                    <RoofingTypeCol key={`roof${item.id}`}>
                      <Radio
                        id={`room${item.id}`}
                        name="roofingTypes"
                        value={item.id}
                        title={item.title}
                        image={item.image}
                        tooltip={item.tooltip}
                        checked={item.checked}
                        disabled={roofingTypesDisabled}
                        onChange={() => this.props.changeRoffingType(item.id)}
                      />
                    </RoofingTypeCol>
                  ))}
                </Row>
              </Container>
            </Section>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  appType,
  viewLoading,
  views: { flashing: viewData },
  translations,
}) => ({
  appType,
  viewLoading,
  viewData,
  translations,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchView: (pageId, appType) => dispatch(fetchViewAction(pageId, appType)),
    changeFlashing: id => dispatch(changeFlashingAction(id)),
    changeRoffingType: id => dispatch(changeRoffingTypeAction(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Flashing);
