const initialState = {
  title: null,
  description: null,
  navbarTitle: null,
  currency: null,
  urlWhereToBuy: null,
  appError: false,
  appLoading: true,
  appType: null,
  activeStepIndex: 0,
  activePath: '/',
  viewLoading: true,
  stepValid: true,
  fromAccessories: false,
  onlyAccessories: false,
  translations: {},
  pages: {
    root: {
      id: null,
      path: null,
    },
    summary: {
      id: null,
      path: null,
    },
    accessories: {
      id: null,
      path: null,
    },
    error: {
      id: null,
      path: null,
    },
  },
  steps: {
    home: [],
    professionals: [],
  },
  sizes: [],
  windows: [],
  accessories: [],
  accessoriesCopy: [],
  views: {
    root: {
      fetched: false,
      header: {
        title: null,
        description: null,
      },
      boxes: [],
    },
    summary: {
      fetched: false,
      header: {
        title: null,
        description: null,
      },
      headerNoResults: {
        title: null,
        description: null,
      },
      headerAccessories: {
        title: null,
        description: null,
      },
    },
    accessories: {
      fetched: false,
      header: {
        title: null,
        description: null,
      },
      headerAccessories: {
        title: null,
        description: null,
      },
    },
    basic: {
      fetched: false,
      roomTypes: [],
      requirements: [],
    },
    additional: {
      fetched: false,
      ranges: [],
    },
    sizes: {
      fetched: false,
      info: [],
      sizesList: [],
    },
    flashing: {
      fetched: false,
      flashings: [],
      roofingTypes: [],
      flashingsDisabled: false,
      roofingTypesDisabled: false,
    },
    technical: {
      fetched: false,
      ug: [],
      uw: [],
      priceRange: {
        min: 1,
        max: 1,
        step: 1,
        currency: null,
        value: [1, 1],
      },
      gas: {
        label: null,
        items: [],
      },
      paneExtras: {
        label: null,
        items: [],
      },
      material: {
        label: null,
        items: [],
      },
      axis: {
        label: null,
        items: [],
      },
      windowExtras: {
        label: null,
        items: [],
      },
    },
  },
};

export default initialState;
