const reducerChangeRange = (state, action) => {
  const ranges = state.views.additional.ranges.map(range => {
    if (range.id === action.payload.id) {
      range.value = +action.payload.value;
    }
    return range;
  });

  return {
    ...state,
    views: {
      ...state.views,
      additional: {
        ...state.views.additional,
        ranges,
      },
    },
  };
};

export { reducerChangeRange };
