import React from 'react';
import styled, { css } from 'styled-components';
import ReactSVG from 'react-svg';
import { iconList } from '../../assets';

const ButtonWrapper = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 0;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.primary};
    pointer-events: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      width: 20px;
      height: 20px;
    }
  }

  ${({ theme, large }) =>
    large &&
    css`
      @media (min-width: ${theme.breakpoints.xl}) {
        svg {
          width: 28px;
          height: 28px;
        }
      }
    `}

  ${({ white }) =>
    white &&
    css`
      svg {
        fill: #fff;
      }
    `}
`;

const Button = ({ icon, ...props }) => (
  <ButtonWrapper {...props}>
    <ReactSVG src={iconList[icon]} />
  </ButtonWrapper>
);

export default Button;
