import React from 'react';
import styled from 'styled-components';
import TooltipTrigger from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import ReactSVG from 'react-svg';
import { iconList } from '../../assets';

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 0;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.primary};
    pointer-events: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      width: 20px;
      height: 20px;
    }
  }
`;

const TooltipContainer = styled.div`
  border-radius: 0;
  border: none;
  padding: 18px;
  transition: opacity 0.3s;
  z-index: 99;
  max-width: 240px;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    max-width: 320px;
  }
`;

const TooltipArrow = styled.div`
  height: 0;
  position: absolute;
  width: 0;

  ::before {
    position: absolute;
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  &[data-placement*='right'] {
    left: 0;
  }

  &[data-placement*='right']::before {
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;

    border-right: 18px solid #fff;
    transform: translate3d(0, -50%, 0);
  }

  &[data-placement*='left'] {
    right: 18px;
  }

  &[data-placement*='left']::before {
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;

    border-left: 18px solid #fff;
    transform: translate3d(0, -50%, 0);
  }
`;

const TooltipContent = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
  padding: 16px;
  font-size: 1.3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.6rem;
  }
`;

const Tooltip = ({ children, ...props }) => (
  <TooltipTrigger
    {...props}
    tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
      <TooltipContainer
        {...getTooltipProps({
          ref: tooltipRef,
        })}
      >
        <TooltipArrow
          {...getArrowProps({
            ref: arrowRef,
            'data-placement': placement,
          })}
        />
        <TooltipContent dangerouslySetInnerHTML={{ __html: children }} />
      </TooltipContainer>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <Button
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger',
        })}
      >
        <ReactSVG src={iconList.tooltip} />
      </Button>
    )}
  </TooltipTrigger>
);

export default Tooltip;
