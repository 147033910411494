import { setCheckedById, setCheckedOneById } from '../../../helpers/store';

const reducerChangeOption = (state, action) => {
  const { type, id } = action.payload;

  let setCheckedFn;

  if (type === 'gas' || type === 'material' || type === 'axis') {
    setCheckedFn = setCheckedOneById;
  } else {
    setCheckedFn = setCheckedById;
  }

  const items = setCheckedFn(state.views.technical[type].items, id);

  return {
    ...state,
    views: {
      ...state.views,
      technical: {
        ...state.views.technical,
        [type]: {
          ...state.views.technical[type],
          items,
        },
      },
    },
  };
};

const reducerChangeUg = (state, action) => {
  const ug = state.views.technical.ug.map(item => {
    item.checked = item.id === action.payload.id;
    return item;
  });

  return {
    ...state,
    views: {
      ...state.views,
      technical: {
        ...state.views.technical,
        ug,
      },
    },
  };
};

const reducerChangeUw = (state, action) => {
  const uw = state.views.technical.uw.map(item => {
    item.checked = item.id === action.payload.id;
    return item;
  });

  return {
    ...state,
    views: {
      ...state.views,
      technical: {
        ...state.views.technical,
        uw,
      },
    },
  };
};

const reducerChangePriceRange = (state, action) => {
  return {
    ...state,
    views: {
      ...state.views,
      technical: {
        ...state.views.technical,
        priceRange: {
          ...state.views.technical.priceRange,
          value: action.payload.value,
        },
      },
    },
  };
};

const reducerValidTechnicalView = state => {
  let valid = false;
  const ug = state.views.technical.ug.find(item => item.checked);
  const uw = state.views.technical.uw.find(item => item.checked);
  const gas = state.views.technical.gas.items.find(item => item.checked);
  const paneExtras = state.views.technical.paneExtras.items.find(item => item.checked);
  const material = state.views.technical.material.items.find(item => item.checked);
  const axis = state.views.technical.axis.items.find(item => item.checked);
  const windowExtras = state.views.technical.windowExtras.items.find(item => item.checked);

  if (
    ug !== undefined ||
    uw !== undefined ||
    gas !== undefined ||
    paneExtras !== undefined ||
    material !== undefined ||
    axis !== undefined ||
    windowExtras !== undefined
  ) {
    valid = true;
  }

  return {
    ...state,
    stepValid: valid,
  };
};

export {
  reducerChangeOption,
  reducerChangeUg,
  reducerChangeUw,
  reducerChangePriceRange,
  reducerValidTechnicalView,
};
