import styled from 'styled-components';

const HeaderDescription = styled.p`
  margin: 24px auto 0;
  max-width: 800px;
  text-align: center;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 32px;
  }
`;

export default HeaderDescription;
