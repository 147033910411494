import styled from 'styled-components';
import { Col } from '../../layout';

export const Box = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 0 64px;
  }
`;

export const RootCol = styled(Col)`
  text-align: center;

  :first-of-type {
    margin-bottom: 24px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-basis: 340px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-basis: 500px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    :first-of-type {
      margin-bottom: 0;
    }
  }
`;
