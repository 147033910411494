import styled from 'styled-components';
import Input from '../Input/Input';
import Select from '../Select/Select';

export const ModalHeader = styled.div`
  background-color: ${({ theme }) => theme.primary};
  text-align: right;
  padding: 6px 12px;
`;

export const ModalContent = styled.div`
  background-color: #fff;
  padding: 32px 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 32px 64px;
  }
`;

export const InputModal = styled(Input)`
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 360px;
  }
`;

export const SelectModal = styled(Select)`
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 360px;
  }
`;

export const ModalContentRow = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0 -16px;
    display: flex;
    align-items: flex-start;

    ${InputModal} {
      flex: 1;
      margin: 16px;
    }
  }
`;

export const ModalTitle = styled.h2`
  font-size: 2.4rem;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 3.2rem;
  }

  span {
    color: ${({ theme }) => theme.primary};
  }
`;

export const ModalDescription = styled.p`
  max-width: 540px;
  margin: 0 auto 32px;
  font-size: 1.8rem;
  text-align: center;
`;

export const Checkbox = styled.div`
  position: relative;

  input {
    position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    white-space: nowrap;

    :checked ~ label {
      &::before {
        background-color: ${({ theme }) => theme.primary};
      }
    }

    :focus ~ label {
      outline: none;

      &::before {
        outline: 2px solid ${({ theme }) => theme.primary};
        outline-offset: 2px;
      }
    }
  }

  label {
    font-size: 1.4rem;

    position: relative;
    padding-left: 24px;
    display: block;
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
    }

    &::before {
      width: 16px;
      height: 16px;

      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      border: 2px solid #000;
    }
  }

  span {
    display: block;
    margin-top: 4px;
    font-size: 1.2rem;
    color: #f00;
  }
`;

export const Alert = styled.p`
  margin-top: 16px;
  text-align: center;
  color: ${({ theme }) => theme.primary};
`;
