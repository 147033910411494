import styled, { css } from 'styled-components';
import { Col } from '../../layout';
import Title from '../../components/Title/Title';
import { imageTechnicalPane, imageTechnicalWindow } from '../../assets';

export const TechnicalTitle = styled(Title)`
  margin-bottom: 24px;
`;

export const TechnicalCol = styled(Col)`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex-basis: 50%;
  }

  ${({ image }) =>
    image &&
    css`
      display: none;
      @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        display: block;
        text-align: right;
      }
    `}
`;

export const TechnicalImg = styled.img`
  display: inline-block;
  max-width: 100%;
  height: auto;
  margin-top: 24px;
`;

export const TechnicalGroup = styled.div`
  margin-bottom: 30px;
  position: relative;

  :last-of-type {
    margin-bottom: 0;
  }
  
  @media (min-width: 1600px) {
  ${({ pane, window }) =>
    (pane || window) &&
    css`
      :before {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
      }
    `}
  
  ${({ pane }) =>
    pane &&
    css`
        :before {
          width: 233px;
          height: 92px;
          background-image: url(${imageTechnicalPane});
          transform: translate3d(-16px, 12px, 0);
      `}
  
  ${({ window }) =>
    window &&
    css`
        :before {
          width: 322px;
          height: 48px;
          background-image: url(${imageTechnicalWindow});
          transform: translate3d(-16px, -4px, 0);
      `}
  }
`;

export const TechnicalOption = styled.div`
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    align-items: center;
  }
`;

export const TechnicalLabel = styled.p`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  flex: 0 0 100px;

  sub {
    font-size: 1.2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex-basis: 150px;
    margin-top: -16px;
  }
`;

export const TechnicalItems = styled.div`
  flex: 1;
  margin-left: -4px;
  margin-right: -4px;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const TechnicalItemsCol = styled.div`
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex: ${({ select }) => (select ? '0 1 292px;' : '0 0 200px;')};
  }
`;

export const PriceRangeLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`;

export const PriceRangeLabel = styled.span`
  color: ${({ theme }) => theme.primary};
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
`;

export const trackStyle = {
  backgroundColor: '#f18500',
  height: '12px',
  borderRadius: 0,
};

export const handleStyle = {
  borderColor: '#000',
  height: '24px',
  width: '8px',
  backgroundColor: '#000',
  borderRadius: 0,
};

export const railStyle = {
  backgroundColor: '#ccc',
  height: '12px',
  borderRadius: 0,
};
