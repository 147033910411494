import { setCheckedOneById } from '../../../helpers/store';

const reducerChangeFlashing = (state, action) => {
  const flashings = state.views.flashing.flashings.map(flashing => {
    flashing.checked = flashing.id.toString() === action.payload.id.toString();
    return flashing;
  });

  const checked = flashings.find(item => item.checked);

  return {
    ...state,
    views: {
      ...state.views,
      flashing: {
        ...state.views.flashing,
        flashingsDisabled: false,
        roofingTypesDisabled: !!checked,
      },
    },
  };
};

const reducerChangeRoffingType = (state, action) => {
  const roofingTypes = setCheckedOneById(state.views.flashing.roofingTypes, action.payload.id);

  const checked = roofingTypes.find(item => item.checked);

  return {
    ...state,
    views: {
      ...state.views,
      flashing: {
        ...state.views.flashing,
        flashingsDisabled: !!checked,
        roofingTypesDisabled: false,
      },
    },
  };
};

export { reducerChangeFlashing, reducerChangeRoffingType };
