import styled, { css } from 'styled-components';
import { Col } from '../../layout';

const SizesCol = styled(Col)`
  :first-of-type {
    margin-bottom: 32px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex-basis: 50%;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-basis: 50%;
    border: 4px solid ${({ theme }) => theme.primary};
    padding: 24px;
  }
`;

const InfoBoxCol = styled.div`
  flex: 0 0 100%;

  :first-of-type {
    margin-bottom: 32px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex-basis: 50%;

    :first-of-type {
      margin-bottom: 0;
    }
  }
`;

const InfoTitle = styled.h3`
  font-size: 2rem;
  color: ${({ theme }) => theme.primary};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  margin-bottom: 16px;
  line-height: 1;

  strong {
    font-weight: ${({ theme }) => theme.fontWeight.black};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 2.4rem;
  }
`;

const InfoParagraph = styled.p`
  font-size: 1.6rem;
  margin-bottom: 16px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.8rem;
  }

  span {
    color: ${({ theme }) => theme.primary};
    font-weight: ${({ theme }) => theme.fontWeight.black};
  }
`;

const Calc = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.primary};
  font-weight: ${({ theme }) => theme.fontWeight.black};

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.8rem;
  }

  input {
    height: 32px;
    width: 64px;
    border: 2px solid #000;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    font-weight: ${({ theme }) => theme.fontWeight.black};
    margin-right: 8px;

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      font-size: 1.8rem;
    }
  }
`;

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
`;

const TableTh = styled.th`
  font-size: 1.4rem;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  background-color: ${({ theme }) => theme.primary};
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  text-align: left;

  &:last-child {
    text-align: right;
  }

  ${({ right }) =>
    right &&
    css`
      text-align: right;
    `}
`;

const TableTr = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.primary};

  :last-of-type {
    border-bottom: none;
  }
`;

const TableTd = styled.td`
  font-size: 1.4rem;
  padding: 10px;

  &:last-child {
    text-align: right;
  }

  strong {
    ${({ theme }) => theme.fontWeight.black};
    text-transform: uppercase;
  }
`;

const Windows = styled.div`
  margin-bottom: 24px;

  :last-of-type {
    margin-bottom: 16px;
  }
`;

const WindowItem = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
  }
`;

const WindowItemCol = styled.div`
  display: flex;
  margin-bottom: 12px;

  > * {
    flex: 1;
    :first-child {
      flex: 0 0 100px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: block;
    margin-bottom: 0;
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const WindowItemLabel = styled.p`
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  line-height: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 1.6rem;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-bottom: 4px;
    text-transform: none;
  }
`;

const WindowItemContent = styled.div`
  ${({ amount }) =>
    amount &&
    css`
      display: flex;
      align-items: center;

      > * {
        :first-child {
          flex: 0 0 128px;
        }
        :last-child {
          margin-left: 12px;
        }
      }
    `}
`;

export {
  SizesCol,
  InfoBox,
  InfoBoxCol,
  InfoTitle,
  InfoParagraph,
  Calc,
  Table,
  TableTh,
  TableTr,
  TableTd,
  Windows,
  WindowItem,
  WindowItemCol,
  WindowItemLabel,
  WindowItemContent,
};
