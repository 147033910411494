import React from 'react';
import styled from 'styled-components';
import ReactSVG from 'react-svg';
import { iconList } from '../../../assets';

const BrandWrapper = styled.a`
  flex-grow: 0;
  display: block;

  svg {
    width: 51px;
    height: 60px;

    @media (min-width: ${({ theme }) => theme.breakpoints[theme.navbarBreakpoint]}) {
      width: 59px;
      height: 70px;
    }
  }
`;

const Brand = () => {
  return (
    <BrandWrapper href="/">
      <ReactSVG src={iconList.logo} />
    </BrandWrapper>
  );
};

export default Brand;
