const reducerFetchAccsessoriesSuccess = (state, action) => {
  return {
    ...state,
    viewLoading: false,
    accessories: action.payload.accessories,
    accessoriesCopy: JSON.parse(JSON.stringify(action.payload.accessories)),
    views: {
      ...state.views,
      accessories: {
        ...action.payload.data,
        fetched: true,
      },
    },
  };
};

const reducerAccsessoriesCancel = state => {
  return {
    ...state,
    accessoriesCopy: JSON.parse(JSON.stringify(state.accessories)),
    activeStepIndex: 0,
    activePath: state.pages.summary.path,
    fromAccessories: true,
  };
};

const reducerAccessoriesComplete = state => {
  return {
    ...state,
    activeStepIndex: 0,
    activePath: state.pages.summary.path,
    fromAccessories: true,
    accessories: JSON.parse(JSON.stringify(state.accessoriesCopy)),
  };
};

const clearRelated = (list, id = null, checked = false) => {
  const related = list.accessories.find(item => item.id === id);
  if(related) {
    related.checked = checked;
    related.colors.forEach(color => {
      color.checked = false;
    });
  }
};

const reducerChangeAccessory = (state, action) => {
  const accessoriesCopy = state.accessoriesCopy.map(group => {
    if (group.name.toLowerCase() === action.payload.size.toLowerCase()) {
      group.windows.forEach(window => {
        window.accessories.forEach(accessory => {
          if (accessory.id === action.payload.id) {
            if (!accessory.colors) {
              accessory.checked = !accessory.checked;
            } else {
              const colorName = action.payload.color;

              const checkedColor = accessory.colors.find(color => color.checked);

              if (colorName) {
                if (checkedColor && checkedColor.color_name === colorName) {
                  accessory.checked = false;
                  accessory.colors.forEach(color => {
                    color.checked = false;
                  });

                  clearRelated(window, accessory.relatedAccessories);
                } else {
                  accessory.checked = true;
                  accessory.colors.forEach(color => {
                    color.checked = color.color_name === colorName;
                  });

                  clearRelated(window, accessory.relatedAccessories, true);
                }
              } else {
                accessory.checked = false;
                accessory.colors.forEach(color => {
                  color.checked = false;
                });

                clearRelated(window, accessory.relatedAccessories);
              }
            }
          }
        });
      });
    }
    return group;
  });

  return {
    ...state,
    accessoriesCopy,
  };
};

const reducerRemoveAccessory = (state, action) => {
  const accessories = state.accessories.map(group => {
    if (group.name.toLowerCase() === action.payload.size.toLowerCase()) {
      group.windows.forEach(window => {
        window.accessories.forEach(accessory => {
          if (accessory.id === action.payload.id) {
            if (!accessory.colors) {
              accessory.checked = !accessory.checked;
            } else {
              const colorName = action.payload.color;

              const checkedColor = accessory.colors.find(color => color.checked);

              if (colorName) {
                if (checkedColor && checkedColor.color_name === colorName) {
                  accessory.checked = false;
                  accessory.colors.forEach(color => {
                    color.checked = false;
                  });
                } else {
                  accessory.checked = true;
                  accessory.colors.forEach(color => {
                    color.checked = color.color_name === colorName;
                  });
                }
              } else {
                accessory.checked = false;
                accessory.colors.forEach(color => {
                  color.checked = false;
                });
              }
            }
          }
        });
      });
    }
    return group;
  });

  return {
    ...state,
    accessories,
    accessoriesCopy: JSON.parse(JSON.stringify(accessories)),
  };
};

const reducerChangeFilter = (state, action) => {
  const filters = state.views.accessories.filters.map(item => {
    if (item.name === action.payload.name) {
      item.checked = !item.checked;
    }
    return item;
  });

  return {
    ...state,
    views: {
      ...state.views,
      accessories: {
        ...state.views.accessories,
        filters,
      },
    },
  };
};

const reducerRemoveAllAccessories = state => {
  const accessories = state.accessories.map(group => {
    group.windows.forEach(window => {
      window.accessories.forEach(accessory => {
        if (!accessory.colors) {
          accessory.checked = false;
        } else {
          accessory.checked = false;
          accessory.colors.forEach(color => {
            color.checked = false;
          });
        }
      });
    });
    return group;
  });

  return {
    ...state,
    accessories,
    accessoriesCopy: JSON.parse(JSON.stringify(accessories)),
  };
};

export {
  reducerFetchAccsessoriesSuccess,
  reducerAccsessoriesCancel,
  reducerAccessoriesComplete,
  reducerChangeAccessory,
  reducerRemoveAccessory,
  reducerChangeFilter,
  reducerRemoveAllAccessories,
};
