import styled from 'styled-components';

const ProductThumbWrapper = styled.div`
  width: 274px;
  height: 274px;
  position: relative;
  display: block;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
`;

const ProductThumbImages = styled.div`
  img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }
`;

const ProductThumbTitleBox = styled.div`
  z-index: 4;
  position: absolute;
  left: 0;
  width: 100%;
  transition: transform 0.2s ease, opacity 0.3s ease;
  bottom: 0;
  text-align: center;
  padding: 16px 8px;
  background-color: ${({ theme }) => theme.primary};
`;

const ProductThumbTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: #fff;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 2rem;
  }
`;

const ProductThumbLink = styled.a`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export {
  ProductThumbWrapper,
  ProductThumbImages,
  ProductThumbTitleBox,
  ProductThumbTitle,
  ProductThumbLink,
};
