const reducerFetchAppStart = state => {
  return {
    ...state,
    appLoading: true,
  };
};

const reducerFetchAppSuccess = (state, action) => {
  const query = new URLSearchParams(window.location.search);
  const onlyAccessories = query.has('accessories');

  return {
    ...state,
    appLoading: false,
    appError: false,
    onlyAccessories,
    activePath: action.payload.data.pages[onlyAccessories ? 'accessories' : 'root'].path,
    ...action.payload.data,
  };
};

const reducerFetchTranslationsSuccess = (state, action) => {
  return {
    ...state,
    translations: {
      ...state.translations,
      ...action.payload.data,
    },
  };
};

const reducerAppError = initialState => {
  return {
    ...initialState,
    appError: true,
  };
};

const reducerSetAppType = (state, action) => {
  return {
    ...state,
    appType: action.payload.type,
    activeStepIndex: 0,
    activePath: state.steps[action.payload.type][0]
      ? state.steps[action.payload.type][0].path
      : '/',
  };
};

const reducerResetApp = (state, initialState) => {
  return {
    ...state,
    appType: null,
    activeStepIndex: 0,
    activePath: state.pages.root.path,
    windows: initialState.windows,
    views: {
      ...initialState.views,
      root: state.views.root,
    },
  };
};

const reducerGoToSummary = state => {
  return {
    ...state,
    activeStepIndex: 0,
    activePath: state.pages.summary.path,
    fromAccessories: false,
  };
};

const reducerGoToAccessories = state => {
  return {
    ...state,
    activeStepIndex: 0,
    activePath: state.pages.accessories.path,
  };
};

const reducerChangeStep = (state, action) => {
  const stepIndex = state.steps[state.appType].findIndex(el => el.id === action.payload.id);

  return {
    ...state,
    activeStepIndex: stepIndex,
    activePath: state.steps[state.appType][stepIndex].path,
  };
};

const reducerNextStep = state => {
  const newStep = state.activeStepIndex + 1;

  return {
    ...state,
    activeStepIndex: newStep,
    activePath: state.steps[state.appType][newStep].path,
  };
};

const reducerFetchViewStart = state => {
  return {
    ...state,
    viewLoading: true,
  };
};

const reducerFetchViewSuccess = (state, action) => {
  return {
    ...state,
    viewLoading: false,
    views: {
      ...state.views,
      [action.payload.view]: {
        ...action.payload.data,
        fetched: true,
      },
    },
  };
};

const reducerAddWindows = (state, action) => {
  return {
    ...state,
    windows: action.payload.windows,
  };
};

export {
  reducerFetchAppStart,
  reducerFetchAppSuccess,
  reducerAppError,
  reducerSetAppType,
  reducerResetApp,
  reducerGoToSummary,
  reducerGoToAccessories,
  reducerChangeStep,
  reducerNextStep,
  reducerFetchTranslationsSuccess,
  reducerFetchViewStart,
  reducerFetchViewSuccess,
  reducerAddWindows,
};
