import styled from 'styled-components';
import Button from '../Button/Button';

const Table = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: flex;
    border: 1px solid #000;
  }
`;

const TableCol = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex: ${({ large }) => (large ? 2 : 1)};
    border: 1px solid #000;
  }
`;

const TableHeader = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    background-color: #000;
    min-height: 60px;
    display: flex;
    align-items: center;
    padding: 4px 12px;
  }
`;

const TableContent = styled.div`
  p {
    text-transform: lowercase;
    margin-bottom: 8px;
    strong {
      text-transform: capitalize;
    }

    font-size: 1.6rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      font-size: 1.8rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 20px;
  }
`;

const ButtonTable = styled(Button)`
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 12px;
  font-size: 2rem;
  text-align: left;

  :hover {
    background: none;
    border: none;
  }

  svg {
    fill: ${({ theme }) => theme.primary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    color: #fff;
    margin-bottom: 0;
  }
`;

export { Table, TableCol, TableHeader, TableContent, ButtonTable };
