import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchView as fetchViewAction } from '../../store/views/root/actions';
import { setAppType as setAppTypeAction } from '../../store/app/actions';
import { Container, Row, Section, LoaderView } from '../../layout';
import ButtonImage from '../../components/ButtonImage/ButtonImage';
import { Box, RootCol } from './Root.styled';

class Root extends Component {
  componentDidMount() {
    const { match, fetchView, viewFetched } = this.props;

    if (!viewFetched) {
      const pageId = match.params.id;
      fetchView(pageId);
    }
  }

  render() {
    const { viewLoading, boxes, setAppType } = this.props;

    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <>
            <Section>
              <Container>
                <Box>
                  <Row center>
                    {boxes.map((box, index) => (
                      <RootCol right={(index + 1) % 2 === 0} key={box.appType}>
                        <ButtonImage
                          title={box.title}
                          titleColour={box.titleColour}
                          image={box.image}
                          onClick={() => setAppType(box.appType)}
                        />
                      </RootCol>
                    ))}
                  </Row>
                </Box>
              </Container>
            </Section>
          </>
        )}
      </>
    );
  }
}

Root.propTypes = {
  viewLoading: PropTypes.bool.isRequired,
  viewFetched: PropTypes.bool.isRequired,
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      titleColour: PropTypes.string,
      image: PropTypes.string,
      appType: PropTypes.string.isRequired,
    }),
  ),
  fetchView: PropTypes.func.isRequired,
  setAppType: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
};

Root.defaultProps = {
  boxes: [],
};

const mapStateToProps = ({
  viewLoading,
  views: {
    root: { fetched: viewFetched, boxes },
  },
}) => ({
  viewLoading,
  viewFetched,
  boxes,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchView: pageId => dispatch(fetchViewAction(pageId)),
    setAppType: type => dispatch(setAppTypeAction(type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Root);
