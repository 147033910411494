import React from 'react';
import PropTypes from 'prop-types';
import { Button, Title, Image } from './ButtonImage.styled';

const ButtonImage = ({ title, titleColour, image, ...props }) => {
  return (
    <Button {...props}>
      {image && <Image url={image} />}
      <Title>
        <p>
          {title}
          {titleColour && <span> {titleColour}</span>}
        </p>
      </Title>
    </Button>
  );
};

ButtonImage.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  titleColour: PropTypes.string,
};

ButtonImage.defaultProps = {
  titleColour: '',
  image: null,
};

export default ButtonImage;
