import { API_PATHS, axiosInstance } from '../../../helpers/api';
import {
  appError,
  fetchTranslationsSuccess,
  fetchViewStart,
  fetchViewSuccess,
} from '../../app/actions';
import { clearResponse } from './clearResponse';
import { fetchTranslations } from '../../../helpers/translation';

const fetchView = (pageId, appType) => async dispatch => {
  try {
    dispatch(fetchViewStart());

    let flashingsData = null;

    const pageData = await axiosInstance.get(API_PATHS.pages + pageId).then(response => {
      return response.data;
    });

    if (appType === 'professionals') {
      flashingsData = await axiosInstance.get(API_PATHS.flashing).then(response => {
        return response.data;
      });
    }

    const translationsList = [
      { key: 'flashingCodes', value: 'Flashing codes' },
      { key: 'or', value: 'or' },
      { key: 'roofingType', value: 'Roofing type' },
    ];

    const translations = await fetchTranslations(translationsList);

    dispatch(fetchTranslationsSuccess(translations));
    dispatch(fetchViewSuccess('flashing', clearResponse(pageData, flashingsData)));
  } catch (error) {
    dispatch(appError());
  }
};

const changeFlashing = id => ({
  type: 'CHANGE_FLASHING',
  payload: {
    id,
  },
});

const changeRoffingType = id => ({
  type: 'CHANGE_ROFFING_TYPE',
  payload: {
    id,
  },
});

export { fetchView, changeFlashing, changeRoffingType };
