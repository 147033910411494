import styled, { css } from 'styled-components';

const Link = styled.a`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  color: #fff;

  ${({ dropdown }) =>
    dropdown &&
    css`
      margin-left: 20px;
      font-size: 1.6rem;
      font-weight: ${({ theme }) => theme.fontWeight.regular};
      text-transform: none;
    `}
`;

export default Link;
