export const clearResponse = ({
  acf: {
    main_title: title,
    subtitle: description,
    accessories_filters: filters,
    accesories_main_title: titleAccessories,
    accessories_subtitle: descriptionAccessories,
  },
}) => {
  return {
    header: {
      title,
      description,
    },
    headerAccessories: {
      title: titleAccessories,
      description: descriptionAccessories,
    },
    filters: filters
      ? filters.map(item => ({
          name: item.filter_label,
          items: item.related_categories,
          checked: false,
        }))
      : [],
  };
};
