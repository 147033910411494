import styled from 'styled-components';

const ViewContent = styled.div`
  padding-bottom: 90px;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-bottom: 128px;
  }
`;

export default ViewContent;
