import styled from 'styled-components';
import {
  Accordion as AccordionBase,
  AccordionItem as AccordionItemBase,
  AccordionItemHeading as AccordionItemHeadingBase,
  AccordionItemPanel as AccordionItemPanelBase,
  AccordionItemButton as AccordionItemButtonBase,
} from 'react-accessible-accordion';
import { Col } from '../../layout';

export const Accordion = styled(AccordionBase)``;

export const AccordionItem = styled(AccordionItemBase)`
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const AccordionItemHeading = styled(AccordionItemHeadingBase)``;

export const AccordionItemPanel = styled(AccordionItemPanelBase)`
  padding: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 32px;
  }
`;

export const AccordionItemButton = styled(AccordionItemButtonBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: #000;

  :hover {
    cursor: pointer;
  }

  :focus {
    outline: 1px dashed #000;
    outline-offset: 1px;
  }

  .accordion-button {
    svg {
      fill: #fff;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 16px 32px;
  }

  &[aria-expanded='true'] {
    background-color: ${({ theme }) => theme.primary};

    .accordion-button {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const AccordionTitle = styled.h3`
  font-size: 2rem;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  color: #fff;

  span {
    text-transform: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 3.2rem;
  }
`;

export const AccessoriesCol = styled(Col)`
  margin-bottom: 12px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-basis: 50%;
    margin-bottom: 24px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex-basis: 25%;
    margin-bottom: 24px;
  }
`;

export const FiltersCol = styled(Col)`
  margin-bottom: 12px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-basis: 50%;
    margin-bottom: 24px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-basis: 33.3333333333%;
    margin-bottom: 24px;
  }
`;
