export const clearResponse = response => {
  const {
    pages,
    headers: {
      basic: { steps: homeSteps },
      advanced: { steps: professionalsSteps },
    },
    ...restResponse
  } = response;

  return {
    ...restResponse,
    urlWhereToBuy: pages.where_to_buy,
    pages: {
      root: {
        id: pages.root,
        path: `/root/${pages.root}`,
      },
      summary: {
        id: pages.summary,
        path: `/summary/${pages.summary}`,
      },
      accessories: {
        id: pages.accessories,
        path: `/accessories/${pages.accessories}`,
      },
      error: {
        id: pages.error,
        path: `/error/${pages.error}`,
      },
    },
    steps: {
      home: getStepsData(homeSteps, ['basic', 'sizes', 'flashing'], 'basic'),
      professionals: getStepsData(professionalsSteps, ['technical', 'sizes', 'flashing'], 'advanced'),
    },
  };
};

const getStepsData = (steps, titles, type) => {
  const data = [];

  if (steps) {
    steps.forEach((step, index) => {
      if (index < titles.length) {
        const pageId = step.step_link ? step.step_link[0] : null;
        data.push({
          id: pageId,
          title: step.step_title,
          description: step[`subtitle_header_${type}`],
          path: `/${titles[index]}/${pageId}`,
          view: titles[index],
        });
      }
    });
  }

  return data;
};
