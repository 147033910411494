import { API_PATHS, axiosInstance } from '../../../helpers/api';
import { appError, fetchViewStart, fetchViewSuccess } from '../../app/actions';
import { clearResponse } from './clearResponse';

const fetchView = pageId => dispatch => {
  dispatch(fetchViewStart());

  axiosInstance
    .get(`${API_PATHS.pages}${pageId}`)
    .then(response => {
      dispatch(fetchViewSuccess('root', clearResponse(response.data)));
    })
    .catch(() => {
      dispatch(appError());
    });
};

export { fetchView };
