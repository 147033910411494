export const clearResponse = ({ acf: response }) => {
  const appTypes = ['home', 'professionals'];

  const responseData = {
    header: {
      title: response.main_title_step_1,
      description: response.subtitle_step_1,
    },
    boxes: [],
  };

  if (response.boxes_step_1) {
    response.boxes_step_1.forEach((box, index) => {
      responseData.boxes.push({
        title: box.box_white_text,
        titleColour: box.box_coloured_text,
        image: box.box_image,
        appType: appTypes[index],
      });
    });
  }
  return responseData;
};
