import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MemoryRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';
import GlobalStyle from './theme/GlobalStyle';
import { fetchApp as fetchAppAction } from './store/app/actions';
import {
  RootView,
  BasicView,
  TechnicalView,
  AdditionalView,
  SizesView,
  FlashingView,
  SummaryView,
  AccessoriesView,
} from './views';
import Loader from './components/Loader/Loader';
import Error from './components/Error/Error';
import Navbar from './components/Navbar/Navbar';
import ViewContent from './components/ViewContent/ViewContent';
import HeaderPage from './components/HeaderPage/HeaderPage';
import HeaderSteps from './components/HeaderSteps/HeaderSteps';
import Footer from './components/Footer/Footer';
import HTMLHead from './components/HTMLHead/HTMLHead';
import { getLangCode } from './helpers/lang';

class App extends Component {
  componentDidMount() {
    this.props.fetchApp();
  }

  renderHeader() {
    const activeView = this.props.activePath.split('/')[1];

    if (
      activeView === 'root' ||
      activeView === 'summary' ||
      activeView === 'accessories' ||
      activeView === 'error'
    ) {
      return <HeaderPage />;
    }
    return <HeaderSteps />;
  }

  render() {
    const { appError, appLoading, activePath, title, description } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <MemoryRouter>
          <>
            <GlobalStyle />
            <HTMLHead lang={getLangCode()} title={title} description={description} />
            {appError ? (
              <Error />
            ) : appLoading ? (
              <Loader />
            ) : (
              <>
                <Navbar />
                {this.renderHeader()}
                <ViewContent>
                  <Switch location={{ pathname: activePath }}>
                    <Route path="/root/:id" exact component={RootView} />
                    <Route path="/basic/:id" component={BasicView} />
                    <Route path="/additional/:id" component={AdditionalView} />
                    <Route path="/sizes/:id" component={SizesView} />
                    <Route path="/sizes/:id" component={SizesView} />
                    <Route path="/flashing/:id" component={FlashingView} />
                    <Route path="/technical/:id" component={TechnicalView} />
                    <Route path="/summary/:id" component={SummaryView} />
                    <Route path="/accessories/:id" component={AccessoriesView} />
                  </Switch>
                </ViewContent>
                <Footer />
              </>
            )}
          </>
        </MemoryRouter>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  fetchApp: PropTypes.func.isRequired,
  appLoading: PropTypes.bool.isRequired,
  appError: PropTypes.bool.isRequired,
  activePath: PropTypes.string.isRequired,
};

const mapStateToProps = ({ appError, appLoading, activePath, title, description }) => ({
  appError,
  appLoading,
  activePath,
  title,
  description,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchApp: () => dispatch(fetchAppAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
