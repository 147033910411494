import { API_PATHS, axiosInstance } from './api';
import { getLangCode } from './lang';

const getTranslation = phrase => {
  const formData = new FormData();
  formData.append('domain', 'dakea');
  formData.append('phrase', phrase);

  const lang = getLangCode();

  return axiosInstance
    .post(`${API_PATHS.translation}${lang !== 'en' ? `?lang=${lang}` : ''}`, formData)
    .then(response => {
      return response.data.translated;
    })
    .catch(error => {
      console.log(error);
    });
};

export const fetchTranslations = async translationsList => {
  const translations = {};
  await Promise.all(translationsList.map(item => getTranslation(item.value))).then(response => {
    translationsList.forEach((translation, index) => {
      translations[translation.key] = response[index];
    });
  });

  return translations;
};
