import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Title = styled.p`
  font-size: 2.4rem;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    ${({ small, large }) =>
      !small &&
      !large &&
      css`
        font-size: 3.2rem;
      `}
  }

  ${({ small }) =>
    small &&
    css`
      font-size: 2rem;
    `}

  ${({ large }) =>
    large &&
    css`
      font-size: 3rem;

      @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        font-size: 6rem;
      }
    `}
  
  ${({ primary }) =>
    primary &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
  
  ${({ left }) =>
    left &&
    css`
      text-align: left;
    `}
  
  ${({ margin }) =>
    margin &&
    css`
      margin-bottom: 24px;

      @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        margin-bottom: 32px;
      }
    `}
  
  span {
    color: ${({ theme }) => theme.primary};
  }
`;

Title.propTypes = {
  as: PropTypes.oneOf(['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

export default Title;
