import React from 'react';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const LoaderIcon = styled.div`
  position: relative;
  margin: 0 auto;
  border: 6px solid ${({ theme }) => theme.secondary};
  transform: translateZ(0);
  animation: kf-loading 1.1s linear infinite;
  border-left-color: ${({ theme }) => theme.primary};
  width: 40px;
  height: 40px;
  border-radius: 50%;

  :after {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  @keyframes kf-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoaderView = () => (
  <LoaderWrapper>
    <LoaderIcon />
  </LoaderWrapper>
);

export default LoaderView;
