import React from 'react';
import styled, { css } from 'styled-components';
import ReactSVG from 'react-svg';
import { iconList } from '../../assets';

const ButtonWrapper = styled.button`
  ${({ theme }) =>
    theme &&
    css`
      display: inline-flex;
      align-items: center;
      min-height: 48px;
      padding: 2px 12px;
      border: 2px solid ${theme.primary};
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: ${theme.fontWeight.black};
      line-height: 1;
      letter-spacing: ${theme.letterSpacing};
      background-color: ${theme.primary};
      color: #fff;

      :hover {
        border-color: ${theme.primaryHover};
        background-color: ${theme.primaryHover};
        transition: ${theme.btnTransition};
      }

      @media (min-width: ${theme.breakpoints.xl}) {
        min-height: 60px;
        padding: 2px 28px;
        font-size: 1.8rem;
      }

      svg {
        width: 16px;
        height: 16px;
        fill: #fff;
        margin-right: 4px;
        pointer-events: none;

        @media (min-width: ${theme.breakpoints.xl}) {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    `}
  
    ${({ theme, disabled }) =>
      disabled &&
      css`
        border-color: ${theme.disabled};
        background-color: ${theme.disabled};

        :hover {
          cursor: not-allowed;
          border-color: ${theme.disabled};
          background-color: ${theme.disabled};
        }
      `}
  
    ${({ theme, outline, disabled }) =>
      outline &&
      css`
        background-color: transparent;
        color: ${!disabled ? theme.primary : theme.disabled};

        svg {
          fill: ${!disabled ? theme.primary : theme.disabled};
        }

        :hover {
          background-color: transparent;
          color: ${!disabled ? theme.primaryHover : theme.disabled};

          svg {
            fill: ${!disabled ? theme.primaryHover : theme.disabled};
          }
        }
      `}
    
    ${({ theme, link, disabled }) =>
      link &&
      css`
        min-height: auto;
        padding: 0;
        border: none;
        background: none;
        color: ${!disabled ? theme.primary : theme.disabled};

        :hover {
          border: none;
          background: none;
        }

        @media (min-width: ${theme.breakpoints.xl}) {
          min-height: auto;
          padding: 0;
        }

        svg {
          fill: ${!disabled ? theme.primary : theme.disabled};
        }
      `}

    ${({ theme, large }) =>
      large &&
      css`
        @media (min-width: ${theme.breakpoints.xl}) {
          font-size: 2.4rem;
        }
      `}
`;

const LoaderIcon = styled.div`
  position: relative;
  margin: 0 auto;
  border: 4px solid ${({ theme }) => theme.secondary};
  transform: translateZ(0);
  animation: kf-loading 1.1s linear infinite;
  border-left-color: ${({ theme }) => theme.primary};
  width: 28px;
  height: 28px;
  border-radius: 50%;

  :after {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  @keyframes kf-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button = ({ icon, children, loading, ...props }) => (
  <ButtonWrapper icon={!!icon} {...props}>
    {loading ? (
      <LoaderIcon />
    ) : (
      <>
        {icon && <ReactSVG src={iconList[icon]} />}
        {children}
      </>
    )}
  </ButtonWrapper>
);

export default Button;
