import React from 'react';

const ContentBasic = ({ data: { roomTypes, requirements }, translations }) => {
  const roomTypesFiltered = roomTypes
    .filter(item => item.checked)
    .map(item => item.title)
    .join(', ');
  const requirementsFiltered = requirements
    .filter(item => item.checked)
    .map(item => item.title)
    .join(', ');

  return (
    <>
      {roomTypesFiltered && (
        <p>
          <strong>{translations.roomType}:</strong> {roomTypesFiltered}
        </p>
      )}

      {requirementsFiltered && (
        <p>
          <strong>{translations.yourRequirements}:</strong> {requirementsFiltered}
        </p>
      )}

      {!roomTypesFiltered && !requirementsFiltered && <p>{translations.notSpecified}</p>}
    </>
  );
};

export default ContentBasic;
