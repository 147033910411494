import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Tab from './Tab/Tab';

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 460px;
  margin: 0 auto;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    justify-content: space-between;
    max-width: ${({ large }) => (large ? '670px' : '500px')};

    &::before {
      content: '';
      position: absolute;
      top: 32px;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 80%;
      height: 1px;
      border-bottom: 4px dotted ${({ theme }) => theme.primary};
    }
  }

  > * {
    flex: 0 0 25%;
  }
`;

const Tabs = ({ items, activeStepIndex, handleClickTab, stepValid, viewLoading, ...props }) => (
  <TabsWrapper disabled={viewLoading} {...props}>
    {items.map((item, index) => (
      <Tab
        key={item.id}
        id={item.id}
        index={index}
        title={item.title}
        handleClickTab={handleClickTab}
        active={activeStepIndex === index}
        disabled={(index > 0 && !stepValid) || viewLoading}
      />
    ))}
  </TabsWrapper>
);

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      view: PropTypes.string,
      number: PropTypes.number,
      title: PropTypes.string,
      active: PropTypes.bool,
      success: PropTypes.bool,
    }),
  ).isRequired,
  handleClickTab: PropTypes.func.isRequired,
};

const mapStateToProps = ({ stepValid, viewLoading }) => ({
  stepValid,
  viewLoading,
});

export default connect(mapStateToProps)(Tabs);
