import initialState from './initialState';

import {
  reducerFetchAppStart,
  reducerFetchAppSuccess,
  reducerAppError,
  reducerSetAppType,
  reducerResetApp,
  reducerGoToSummary,
  reducerChangeStep,
  reducerNextStep,
  reducerGoToAccessories,
  reducerFetchTranslationsSuccess,
  reducerFetchViewStart,
  reducerFetchViewSuccess,
  reducerAddWindows,
} from './app/reducers';
import {
  reducerChangeRoomType,
  reducerChangeRequirement,
  reducerValidBasicView,
} from './views/basic/reducers';
import { reducerChangeRange } from './views/additional/reducers';
import {
  reducerAddSize,
  reducerChangeSize,
  reducerChangeSizeCount,
  reducerDeleteSize,
} from './views/sizes/reducers';
import { reducerChangeFlashing, reducerChangeRoffingType } from './views/flashing/reducers';
import {
  reducerChangeOption,
  reducerChangeUg,
  reducerChangeUw,
  reducerChangePriceRange,
  reducerValidTechnicalView,
} from './views/technical/reducers';
import {
  reducerFetchSummaryAccessoriesSuccess,
  reducerFetchSummarySuccess,
} from './views/summary/reducers';
import {
  reducerAccsessoriesCancel,
  reducerAccessoriesComplete,
  reducerFetchAccsessoriesSuccess,
  reducerChangeAccessory,
  reducerRemoveAccessory,
  reducerChangeFilter,
  reducerRemoveAllAccessories,
} from './views/accessories/reducers';

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_APP_START':
      return reducerFetchAppStart(state);
    case 'FETCH_APP_SUCCESS':
      return reducerFetchAppSuccess(state, action);
    case 'FETCH_TRANSLATIONS_SUCCESS':
      return reducerFetchTranslationsSuccess(state, action);
    case 'APP_ERROR':
      return reducerAppError(initialState);
    case 'SET_APP_TYPE':
      return reducerSetAppType(state, action);
    case 'RESET_APP':
      return reducerResetApp(state, initialState);
    case 'FETCH_VIEW_START':
      return reducerFetchViewStart(state);
    case 'FETCH_VIEW_SUCCESS':
      return reducerFetchViewSuccess(state, action);
    case 'GO_TO_SUMMARY':
      return reducerGoToSummary(state);
    case 'GO_TO_ACCESSORIES':
      return reducerGoToAccessories(state);
    case 'CHANGE_STEP':
      return reducerChangeStep(state, action);
    case 'NEXT_STEP':
      return reducerNextStep(state);
    case 'CHANGE_ROOM_TYPE':
      return reducerChangeRoomType(state, action);
    case 'CHANGE_REQUIREMENT':
      return reducerChangeRequirement(state, action);
    case 'CHANGE_RANGE':
      return reducerChangeRange(state, action);
    case 'CHANGE_FLASHING':
      return reducerChangeFlashing(state, action);
    case 'CHANGE_ROFFING_TYPE':
      return reducerChangeRoffingType(state, action);
    case 'ADD_SIZE':
      return reducerAddSize(state);
    case 'CHANGE_SIZE':
      return reducerChangeSize(state, action);
    case 'CHANGE_SIZE_COUNT':
      return reducerChangeSizeCount(state, action);
    case 'DELETE_SIZE':
      return reducerDeleteSize(state, action);
    case 'CHANGE_OPTION':
      return reducerChangeOption(state, action);
    case 'CHANGE_UG':
      return reducerChangeUg(state, action);
    case 'CHANGE_UW':
      return reducerChangeUw(state, action);
    case 'CHANGE_PRICE_RANGE':
      return reducerChangePriceRange(state, action);
    case 'VALID_BASIC_VIEW':
      return reducerValidBasicView(state);
    case 'VALID_TECHNICAL_VIEW':
      return reducerValidTechnicalView(state);
    case 'FETCH_SUMMARY_SUCCESS':
      return reducerFetchSummarySuccess(state, action);
    case 'FETCH_ACCESSORIES_SUCCESS':
      return reducerFetchAccsessoriesSuccess(state, action);
    case 'ACCESSORIES_CANCEL':
      return reducerAccsessoriesCancel(state);
    case 'ACCESSORIES_COMPLETE':
      return reducerAccessoriesComplete(state);
    case 'CHANGE_ACCESSORY':
      return reducerChangeAccessory(state, action);
    case 'REMOVE_ACCESSORY':
      return reducerRemoveAccessory(state, action);
    case 'CHANGE_FILTER':
      return reducerChangeFilter(state, action);
    case 'ADD_WINDOWS':
      return reducerAddWindows(state, action);
    case 'FETCH_SUMMARY_ACCESSORIES_SUCCESS':
      return reducerFetchSummaryAccessoriesSuccess(state, action);
    case 'REMOVE_ALL_ACCESSORIES':
      return reducerRemoveAllAccessories(state);
    default:
      return state;
  }
};

export default reducers;
