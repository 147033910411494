import styled from 'styled-components';

const Line = styled.span`
  position: absolute;
  left: 50%;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: transform 0.2s, opacity 0.2s;

  &:nth-of-type(1) {
    top: 0;
    transform: translate3d(-50%, 0, 0);
  }

  &:nth-of-type(2) {
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &:nth-of-type(3) {
    bottom: 0;
    transform: translate3d(-50%, 0, 0);
  }
`;

export default Line;
