import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '../../layout/Container/Container';

const HeaderWrapper = styled.div`
  padding: 32px 0;
  background-color: ${({ theme }) => theme.gray100};
`;

const Header = ({ children }) =>
  children ? (
    <HeaderWrapper>
      <Container>{children}</Container>
    </HeaderWrapper>
  ) : null;

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

export default Header;
