import uniqBy from 'lodash/unionBy';

export const getAccessoriesUniqIds = accessoriesList => {
  if (accessoriesList) {
    const accessoriesListIds = [];
    if (accessoriesList.length > 0) {
      accessoriesList.forEach(group => {
        group.windows.forEach(window => {
          const checkedAccessories = window.accessories
            .filter(accessory => accessory.checked)
            .map(accessory => accessory.id);

          if (checkedAccessories) {
            accessoriesListIds.push(...checkedAccessories);
          }
        });
      });
    }

    return uniqBy(accessoriesListIds);
  }

  return [];
};
