export const clearResponse = ({ acf: { roofing_type_boxes: roofingTypes = [] } }, flashings) => {
  return {
    roofingTypes: roofingTypes.map(item => ({
      id: item.system_value,
      title: item.box_title,
      image: item.box_image,
      tooltip: item.box_tooltip,
      checked: false,
    })),
    flashings: flashings
      ? flashings.map(item => ({
          id: item.id,
          title: item.name,
          checked: false,
        }))
      : [],
    flashingsDisabled: false,
    roofingTypesDisabled: false,
  };
};
