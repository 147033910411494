import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  goToSummary as goToSummaryAction,
  nextStep as nextStepAction,
  resetApp as resetAppAction,
  goToAccessories as goToAccessoriesAction,
} from '../../store/app/actions';
import {
  accessoriesCancel as accessoriesCancelAction,
  accessoriesComplete as accessoriesCompleteAction,
  removeAllAccessories as removeAllAccessoriesAction,
} from '../../store/views/accessories/actions';
import Button from '../Button/Button';
import {
  FooterWrapper,
  FooterContainer,
  FooterRow,
  FooterCol,
  TitleFooter,
  FooterButtons,
} from './Footer.styled';
import { getAccessoriesUniqIds } from '../../helpers/accessories';

class Footer extends Component {
  getViewName() {
    return this.props.activePath.split('/')[1];
  }

  renderDefaultContent = () => (
    <>
      <FooterCol right>
        <Button
          onClick={() => this.props.nextStep()}
          disabled={!this.props.stepValid || this.props.viewLoading}
        >
          {this.props.translations.nextStep}
        </Button>
      </FooterCol>
      <FooterCol>
        <Button
          icon="reset"
          link
          onClick={() => this.props.resetApp()}
          disabled={this.props.viewLoading}
        >
          {this.props.translations.startOver}
        </Button>
      </FooterCol>
    </>
  );

  renderSteps = () => (
    <>
      <FooterCol right>
        <Button outline onClick={() => this.props.goToSummary()} disabled={this.props.viewLoading}>
          {this.props.translations.goToSummary}
        </Button>
        <Button
          style={{ marginLeft: '24px' }}
          onClick={() => this.props.nextStep()}
          disabled={this.props.viewLoading}
        >
          {this.props.translations.nextStep}
        </Button>
      </FooterCol>
      <FooterCol>
        <Button
          icon="reset"
          link
          onClick={() => this.props.resetApp()}
          disabled={this.props.viewLoading}
        >
          {this.props.translations.startOver}
        </Button>
      </FooterCol>
    </>
  );

  renderStepsComplete = () => (
    <>
      <FooterCol right>
        <Button outline onClick={() => this.props.goToSummary()} disabled={this.props.viewLoading}>
          {this.props.translations.complete}
        </Button>
      </FooterCol>
      <FooterCol>
        <Button
          icon="reset"
          link
          onClick={() => this.props.resetApp()}
          disabled={this.props.viewLoading}
        >
          {this.props.translations.startOver}
        </Button>
      </FooterCol>
    </>
  );

  renderSummary = () => {
    const { onlyAccessories, removeAllAccessories, goToAccessories } = this.props;

    return (
      <>
        <FooterCol summary>
          <TitleFooter
            dangerouslySetInnerHTML={{ __html: `${this.props.translations.whatsNext}` }}
          />
        </FooterCol>
        <FooterCol right summary>
          <FooterButtons>
            <Button
              as="a"
              href={this.props.urlWhereToBuy}
              link
              large
              target="_blank"
              disabled={this.props.viewLoading}
            >
              {this.props.translations.whereToBuy}
            </Button>
            <Button
              icon="reset"
              link
              large
              onClick={() => {
                if (onlyAccessories) {
                  removeAllAccessories();
                  goToAccessories();
                } else {
                  this.props.resetApp();
                }
              }}
              disabled={this.props.viewLoading}
            >
              {this.props.translations.startOver}
            </Button>
          </FooterButtons>
        </FooterCol>
      </>
    );
  };

  renderAccessories = () => {
    const { onlyAccessories, accessoriesCopy } = this.props;

    const accessoriesList = getAccessoriesUniqIds(accessoriesCopy);

    return (
      <>
        <FooterCol right>
          <Button
            outline
            onClick={() => this.props.accessoriesComplete()}
            disabled={this.props.viewLoading || accessoriesList.length === 0}
          >
            {this.props.translations.complete}
          </Button>
        </FooterCol>
        {!onlyAccessories && (
          <FooterCol>
            <Button
              icon="reset"
              link
              onClick={() => this.props.accessoriesCancel()}
              disabled={this.props.viewLoading}
            >
              {this.props.translations.cancelAndGoBackToSummary}
            </Button>
          </FooterCol>
        )}
      </>
    );
  };

  renderContent() {
    switch (this.getViewName()) {
      case 'root':
        return null;
      case 'basic':
        return this.renderDefaultContent();
      case 'additional':
        return this.renderSteps();
      case 'sizes':
        return this.renderSteps();
      case 'flashing':
        return this.renderStepsComplete();
      case 'technical':
        return this.renderDefaultContent();
      case 'summary':
        return this.renderSummary();
      case 'accessories':
        return this.renderAccessories();
      default:
        return null;
    }
  }

  render() {
    const footerContent = this.renderContent();
    return (
      footerContent && (
        <FooterWrapper>
          <FooterContainer>
            <FooterRow>{footerContent}</FooterRow>
          </FooterContainer>
        </FooterWrapper>
      )
    );
  }
}

const mapStateToProps = ({
  activePath,
  stepValid,
  viewLoading,
  urlWhereToBuy,
  translations,
  onlyAccessories,
  accessoriesCopy,
}) => ({
  activePath,
  stepValid,
  viewLoading,
  urlWhereToBuy,
  translations,
  onlyAccessories,
  accessoriesCopy,
});

const mapDispatchToProps = dispatch => {
  return {
    resetApp: () => dispatch(resetAppAction()),
    goToSummary: () => dispatch(goToSummaryAction()),
    nextStep: () => dispatch(nextStepAction()),
    accessoriesCancel: () => dispatch(accessoriesCancelAction()),
    accessoriesComplete: () => dispatch(accessoriesCompleteAction()),
    removeAllAccessories: () => dispatch(removeAllAccessoriesAction()),
    goToAccessories: () => dispatch(goToAccessoriesAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Footer);
