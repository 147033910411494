import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactSVG from 'react-svg';
import Button from './Button';
import Link from './Link';
import { iconList } from '../../../../assets';

const List = styled.ul`
  margin-top: 32px;

  ${({ dropdown }) =>
    dropdown &&
    css`
      margin-top: 0;
    `}
`;

const Item = styled.li`
  margin-bottom: 24px;

  ${({ dropdown }) =>
    dropdown &&
    css`
      position: relative;
      margin-top: 14px;

      ${List} {
        display: ${({ open }) => (open ? 'block' : 'none')};
      }
    `}

  ${({ mb0 }) =>
    mb0 &&
    css`
      margin-bottom: 0;
    `}
`;

const renderChilds = children => (
  <List dropdown>
    {children.map(({ id, title, url }) => (
      <Item dropdown mb0 key={id}>
        <Link href={url} dropdown>
          {title}
        </Link>
      </Item>
    ))}
  </List>
);

const NavList = ({ items, dropdownClickFn }) => (
  <List>
    {items.map(({ id, title, url, open, children }) =>
      children ? (
        <Item dropdown open={open} key={id}>
          <Button onClick={() => dropdownClickFn(id)}>
            {title}
            <ReactSVG src={iconList.arrowDown} />
          </Button>
          {renderChilds(children)}
        </Item>
      ) : (
        <Item key={id}>
          <Link href={url}>{title}</Link>
        </Item>
      ),
    )}
  </List>
);

NavList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      open: PropTypes.bool,
      childs: PropTypes.array,
    }),
  ).isRequired,
  dropdownClickFn: PropTypes.func.isRequired,
};

export default NavList;
