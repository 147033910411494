import React, {Component} from 'react';
import ReactModal from 'react-modal';
import {connect} from 'react-redux';
import Button from '../Button/Button';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import {API_PATHS, axiosInstance} from '../../helpers/api';
import {
    Alert,
    Checkbox,
    InputModal,
    ModalContent,
    ModalContentRow,
    ModalDescription,
    ModalHeader,
    ModalTitle,
    SelectModal,
} from './ModalPdf.styled';
import initialState from './initialState';

class ModalPdf extends Component {
    state = this.getInitialState();

    fetchWindowNames = () => {
        const {windows} = this.props;

        if (this.state.windowItems !== false) {
            return;
        }

        this.windowItems = true;
        const sliderFormData = new FormData();

        if (windows && windows.length > 0) {
            windows.forEach(item => {
                sliderFormData.append('windows[]', item);
            });
        }

        axiosInstance.post(API_PATHS.productsSlider, sliderFormData).then(response => {
            const items = response.data;
            const itemsList = [];
            for (const key in items) {
                const item = items[key];
                if (item.acf) {
                    itemsList.push({
                        id: item.id,
                        title: item.acf[0].product_text,
                        window_id: item.window_id
                    });
                }
            }

            this.setState({
                windowItems: itemsList
            });
            this.generateInquiry();
        });
    };

    generateInquiry = () => {
        const {views, accessories, sizes} = this.props;
        const windows = this.state.windowItems;

        let content = [];
        content['step_1'] = '';
        content['step_2'] = '';
        content['step_3'] = '';
        content['accessories'] = '';
        content['windows'] = '';

        //step 1
        let anyAdded = false;
        views.basic.requirements.forEach(item => {
            if (item.checked) {
                content['step_1'] += item.title + ',';
                anyAdded = true;
            }
        });

        views.basic.roomTypes.forEach(item => {
            if (item.checked) {
                content['step_1'] += item.title + ',';
                anyAdded = true;
            }
        });

        if (anyAdded) {
            content['step_1'] = content['step_1'].slice(0, -1);
        }

        //step 2
        anyAdded = false;
        sizes.forEach(item => {
            if (item.id) {
                content['step_2'] += item.title + '(' + item.count + '),';
                anyAdded = true;
            }
        });
        if (anyAdded) {
            content['step_2'] = content['step_2'].slice(0, -1);
        }

        //step 3
        anyAdded = false;
        views.flashing.roofingTypes.forEach(item => {
            if (item.checked) {
                content['step_3'] += item.title + ',';
                anyAdded = true;
            }
        });
        if (anyAdded) {
            content['step_3'] = content['step_3'].slice(0, -1);
        }

        //ACCESSORIES - wtf
        let accessoriesContent = '';
        accessories.forEach(item => {
            let anyAdded = false;
            accessoriesContent += item.name + "(";
            item.windows.forEach(window => {

                //resolve window id to name, fallback to id
                let windowName = window.windowId;
                windows.forEach(gWindow => {
                    gWindow.window_id.forEach(id => {
                        if (parseInt(id) === parseInt(window.windowId)) {
                            windowName = gWindow.title;
                        }
                    });
                });
                let tmpWindowContent = windowName + '-';

                window.accessories.forEach(accessory => {
                    let anyChecked = false;
                    if (accessory.checked) {
                        tmpWindowContent += accessory.name;
                        anyChecked = true;
                        if (accessory.colors) {
                            accessory.colors.forEach(color => {
                                if (color.checked) {
                                    tmpWindowContent += '-' + color.color_name;
                                }
                            });
                        }
                    }
                    if (anyChecked) {
                        accessoriesContent += tmpWindowContent + ',';
                        anyAdded = true;
                        tmpWindowContent = windowName + '-';
                    }
                });

            });
            if (anyAdded) {
                accessoriesContent = accessoriesContent.slice(0, -1);
            }
            accessoriesContent += ")";
        });

        if (accessoriesContent !== '') {
            content['accessories'] += accessoriesContent;
        }

        // windows
        anyAdded = false;
        windows.forEach(item => {
            content['windows'] += item.title + ',';
            anyAdded = true;
        });

        if (anyAdded) {
            content['windows'] = content['windows'].slice(0, -1);
        }

        this.setState({
            inquiry: content
        });
    };


    getInitialState() {
        const newState = JSON.parse(JSON.stringify(initialState));

        this.props.form.acceptances.forEach(item => {
            newState.fields[item.acceptance_id] = {
                value: '',
                error: '',
            };
        });

        return newState;
    }

    clearAlerts() {
        const stateFields = this.state.fields;

        for (const field in stateFields) {
            if (stateFields[field].hasOwnProperty('error')) {
                stateFields[field].error = '';
            }
        }

        this.setState(() => ({message: '', fields: stateFields}));
    }

    validationFailed(errors) {
        const {fields} = this.state;
        errors.forEach(error => {
            const field = error.into
                .split('.')
                .splice(-1, 1)
                .join('');

            fields[field].error = error.message;
        });

        this.setState(() => ({
            fields,
        }));
    }

    formMessage(message) {
        this.setState(() => ({message}));
    }

    getTypeOfContact() {
        const list = [];
        this.props.form.types_of_contact.forEach(item => {
            if(item.title) {
                if (item.title.indexOf('|') >= 0) {
                    let items = item.title.split('|');
                    list.push({
                        id: items[1],
                        title: items[0],
                    });
                } else {
                    list.push({
                        id: item.title,
                        title: item.title,
                    });
                }
            }
        });

        return list;
    }

    getTypeOfRegion() {
        const list = [];
        this.props.form.region.forEach(item => {
            if(item.title) {
                if (item.title.indexOf('|') >= 0) {
                    let items = item.title.split('|');
                    list.push({
                        id: items[1],
                        title: items[0],
                    });
                } else {
                    list.push({
                        id: item.title,
                        title: item.title,
                    });
                }
            }
        });

        return list;
    }

    handleSubmit(e) {
        e.preventDefault();

        const formData = new FormData();

        formData.append('person-name', this.state.fields['person-name'].value);
        formData.append('person-surname', this.state.fields['person-surname'].value);
        formData.append('person-email', this.state.fields['person-email'].value);
        formData.append('person-phone', this.state.fields['person-phone'].value);
        {document.documentElement.lang === "pl" &&(
        formData.append('person-province', this.state.fields['person-province'].value)
        )};
        formData.append('inquiry_step_1', this.state.inquiry['step_1']);
        formData.append('inquiry_step_2', this.state.inquiry['step_2']);
        formData.append('inquiry_step_3', this.state.inquiry['step_3']);
        formData.append('inquiry_accessories', this.state.inquiry['accessories']);
        formData.append('inquiry_windows', this.state.inquiry['windows']);

        formData.append(
            'subject',
            this.state.fields.subject.value === 'none' ? '' : this.state.fields.subject.value,
        );

        formData.append(
            'region',
            this.state.fields.region.value === 'none' ? '' : this.state.fields.region.value,
        );
        //formData.append('message', this.state.fields.message.value);

        this.props.form.acceptances.forEach(item => {
            formData.append(item.acceptance_id, this.state.fields[item.acceptance_id].value);
        });

        this.clearAlerts();
        this.setState(() => ({loading: true}));

        axiosInstance
            .post(API_PATHS.form.replace(':id', this.props.form.form_object), formData)
            .then(response => {
                const {data} = response;
                switch (data.status) {
                    case 'mail_failed':
                        this.formMessage(data.message);
                        break;
                    case 'validation_failed':
                        this.validationFailed(data.invalidFields);
                        this.formMessage(data.message);
                        break;
                    case 'mail_sent':
                        this.setState(() => this.getInitialState());
                        this.formMessage(data.message);
                        this.props.sendFn();
                        break;
                    default:
                        break;
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                this.setState(() => ({loading: false}));
            });
    }

    handleChange = event => {
        const {target} = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const {name} = target;

        this.setState(prevState => ({
            fields: {
                ...prevState.fields,
                [name]: {
                    ...prevState.fields[name],
                    value: value ? value : '', // eslint-disable-line
                },
            },
        }));
    };

    renderInput = (name, placeholder) => (
        <InputModal
            placeholder={placeholder}
            type="text"
            name={name}
            value={this.state.fields[name].value}
            error={this.state.fields[name].error}
            onChange={this.handleChange}
        />
    );

    componentDidMount() {
        this.fetchWindowNames();
    }

    render() {
        const {showModal, closeModalFn, download, form, translations} = this.props;
        return (
            <ReactModal isOpen={showModal} onRequestClose={closeModalFn} ariaHideApp={false}>
                <ModalHeader>
                    <ButtonIcon icon="close" white onClick={closeModalFn}/>
                </ModalHeader>
                <ModalContent>
                    <form onSubmit={e => this.handleSubmit(e)}>
                        <ModalTitle>
                            <span>{form.header}</span>
                        </ModalTitle>
                        <ModalDescription>{form.subheader}</ModalDescription>
                        <ModalContentRow>
                            {this.renderInput('person-name', translations.firstName)}
                            {this.renderInput('person-surname', translations.surname)}
                        </ModalContentRow>
                        <ModalContentRow>
                            {this.renderInput('person-email', translations.email)}
                        </ModalContentRow>
                        {document.documentElement.lang === "pl" &&(
                        <ModalContentRow>
                            {this.renderInput('person-province', translations.province)}
                        </ModalContentRow>
                         )}
                        <SelectModal
                            name="subject"
                            options={this.getTypeOfContact()}
                            value={this.state.fields.subject.value}
                            error={this.state.fields.subject.error}
                            placeholder={translations.typeOfContact}
                            onChange={this.handleChange}
                        />
                        {document.documentElement.lang === "en" &&(
                          <SelectModal
                            name="region"
                            options={this.getTypeOfRegion()}
                            value={this.state.fields.region.value}
                            error={this.state.fields.region.error}
                            placeholder={translations.typeOfRegion}
                            onChange={this.handleChange}
                        />
                        )}
                        {document.documentElement.lang === "hu" &&(
                          <SelectModal
                            name="region"
                            options={this.getTypeOfRegion()}
                            value={this.state.fields.region.value}
                            error={this.state.fields.region.error}
                            placeholder="Megye"
                            onChange={this.handleChange}
                        />
                        )}
                        {form.cta_1 !== null && (
                            <span style={{display: "block", width: "100%", fontWeight:"bold"}} dangerouslySetInnerHTML={{__html:form.cta_1}}></span>
                        )}
                        {form.cta_2 !== null && (
                            <span style={{
                                display: "block",
                                width: "100%",
                                marginBottom: "20px",
                                fontWeight:"bold"
                            }}
                                  dangerouslySetInnerHTML={{__html:form.cta_2}}></span>
                        )}
                        {this.renderInput('person-phone', translations.phone)}

                        {form.acceptances.map(item => (
                            <Checkbox key={item.acceptance_id} style={{marginTop: '16px'}}>
                                <input
                                    type="checkbox"
                                    id={item.acceptance_id}
                                    name={item.acceptance_id}
                                    value={this.state.fields[item.acceptance_id].value}
                                    checked={!!this.state.fields[item.acceptance_id].value}
                                    onChange={this.handleChange}
                                />
                                <label
                                    htmlFor={item.acceptance_id}
                                    dangerouslySetInnerHTML={{__html: `${item.acceptance_content}`}}
                                />
                                {this.state.fields[item.acceptance_id].error && (
                                    <span>{this.state.fields[item.acceptance_id].error}</span>
                                )}
                            </Checkbox>
                        ))}
                        <div style={{marginTop: '32px', textAlign: 'center'}}>
                            <Button
                                type="submit"
                                icon="pdf"
                                disabled={download || this.state.loading}
                                loading={download || this.state.loading}
                                style={{marginLeft: '15px', marginRight: '15px'}}
                            >
                                {translations.generatePdf}
                            </Button>
                            {form.custom_button_text !== null && (
                                <a href={form.custom_button_link} target="_blank" rel="noopener noreferrer"
                                   style={{marginLeft: '15px', marginRight: '15px'}}>
                                    <Button
                                        type="button"
                                    >
                                        {form.custom_button_text}
                                    </Button>
                                </a>
                            )}
                            {this.state.message && <Alert>{this.state.message}</Alert>}
                        </div>
                    </form>
                </ModalContent>
            </ReactModal>
        );
    }
}

const mapStateToProps = ({
                             form,
                             translations,
                             views,
                             windows,
                             fromAccessories,
                             accessories,
                             onlyAccessories,
                             sizes
                         }) => ({
    form,
    translations,
    views,
    windows,
    fromAccessories,
    accessories,
    onlyAccessories,
    sizes
});

export default connect(mapStateToProps)(ModalPdf);
