const reducerFetchSummarySuccess = (state, action) => {
  return {
    ...state,
    viewLoading: false,
    windows: action.payload.windows,
    accessories: [],
    accessoriesCopy: [],
    views: {
      ...state.views,
      summary: {
        ...action.payload.data,
        fetched: true,
      },
    },
  };
};

const reducerFetchSummaryAccessoriesSuccess = (state, action) => {
  return {
    ...state,
    viewLoading: false,
    views: {
      ...state.views,
      summary: {
        ...action.payload.data,
        fetched: true,
      },
    },
  };
};

export { reducerFetchSummarySuccess, reducerFetchSummaryAccessoriesSuccess };
