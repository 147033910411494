import styled from 'styled-components';

export const Button = styled.button`
  display: inline-block;
  width: 100%;
  max-width: 340px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 500px;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 240px;
  background: url(${({ url }) => url}) center / cover no-repeat;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 340px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding: 6px 12px;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  background-color: #000;
  color: #fff;

  span {
    color: ${({ theme }) => theme.primary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    min-height: 80px;
    font-size: 2.4rem;
  }
`;
