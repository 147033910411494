export const clearResponse = (response, getState) => {
  const { translations } = getState();
  const {
    page: { acf: page },
    ...options
  } = response;

  const responseFormat = {
    ug: [
      {
        id: '0.99',
        title: '< 0.99',
        checked: false,
      },
      {
        id: '1.0',
        title: '> 1.00',
        checked: false,
      },
    ],
    uw: [
      {
        id: '1.0',
        title: '< 1.0',
        checked: false,
      },
      {
        id: '1.1 - 1.2',
        title: '1.1 - 1.2',
        checked: false,
      },
      {
        id: '1.3',
        title: '> 1.3',
        checked: false,
      },
    ],
    priceRange: {
      label: translations.priceRange,
      min: +page.price_min,
      max: +page.price_max,
      step: +page.step,
      currency: '£',
      value: [+page.price_min, +page.price_max],
    },
    gas: {
      label: translations.gas,
      name: 'gas',
      items: [],
    },
    paneExtras: {
      label: translations.extras,
      name: 'paneExtras',
      items: [],
    },
    material: {
      label: translations.material,
      name: 'material',
      items: [],
    },
    axis: {
      label: translations.pivotType,
      name: 'axis',
      items: [],
    },
    windowExtras: {
      label: translations.extras,
      name: 'windowExtras',
      items: [],
    },
  };

  Object.keys(options).forEach(key => {
    options[key].forEach(item => {
      responseFormat[key].items.push({
        id: item.id,
        title: item.name,
        tooltip: null,
        checked: false,
      });
    });
  });

  return responseFormat;
};
