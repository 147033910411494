import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import Tooltip from '../Tooltip/Tooltip';
import ButtonIcon from '../ButtonIcon/ButtonIcon';

const RadioWrapper = styled.div`
  display: inline-block;
  width: 100%;
`;

const Icon = styled.div`
  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.primary};
    margin-right: 8px;
  }

  ${({ tooltip }) =>
    tooltip &&
    css`
      svg {
        width: 20px;
        height: 20px;
        fill: #fff;
        margin-right: 0;
      }
    `}
`;

const Info = styled.div`
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  padding: 4px 10px;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  background-color: #000;
  color: ${({ theme }) => theme.primary};
  line-height: 1;

  > * {
    flex: 1;
  }

  > ${Icon} {
    flex: 0;
  }
`;

const Input = styled.input`
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;

  :checked {
    ~ label {
      ${Description} {
        background-color: ${({ theme }) => theme.primary};
        color: #fff;

        ${Info} {
          ${Icon} {
            svg {
              fill: #fff;
            }
          }
        }
      }
    }
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;

  :hover {
    cursor: pointer;
  }

  ${({ image }) =>
    image &&
    css`
      padding-bottom: 100%;
      background: url(${image}) center / cover no-repeat;

      ${Description} {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      :hover {
        cursor: not-allowed;
      }

      ${Description} {
        background-color: ${({ theme }) => theme.disabled};
      }
    `}
`;

const Colors = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 24px;
`;

const ColorsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px -5px;
`;

const Color = styled.button`
  width: 40px;
  height: 40px;
  border: 3px solid ${({ active, theme }) => (active ? theme.primary : '#000')};
  background-color: transparent;
  margin: 10px 5px;
`;

const ColorsTitle = styled.p`
  font-size: 1.8rem;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  text-align: center;
`;

class AccessoriesItem extends Component {
  state = {
    open: false,
  };

  handleToggleColorBox = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  handleChange = () => {
    const { itemId, size, onChangeFn, colors, checked } = this.props;

    if (colors && !checked) {
      this.setState(() => ({ open: true }));
    } else {
      onChangeFn(size, itemId);
    }
  };

  handleClickColor = (color, itemId) => {
    const { size, onChangeFn } = this.props;
    onChangeFn(size, itemId, color);
  };

  render() {
    const { id, title, image, colors, tooltip, onChangeFn, translations, ...props } = this.props;

    return (
      <RadioWrapper>
        <Input type="checkbox" id={id} {...props} onChange={this.handleChange} />
        <Label htmlFor={id} image={image}>
          {colors && this.state.open && (
            <Colors>
              <ColorsTitle>{translations.selectAColour}</ColorsTitle>
              <ColorsBox>
                {colors.map(color => (
                  <Color
                    key={`${color.color_name}_${color.itemId}`}
                    active={color.checked}
                    style={{ backgroundColor: color.color }}
                    onClick={() => this.handleClickColor(color.color_name, color.itemId)}
                  />
                ))}
              </ColorsBox>
            </Colors>
          )}
          <Description>
            <Info>
              {colors && (
                <Icon>
                  <ButtonIcon icon="palette" onClick={this.handleToggleColorBox} />
                </Icon>
              )}
              <p>{title}</p>
            </Info>
            {tooltip && (
              <Icon tooltip>
                <Tooltip placement="right" trigger="click">
                  {tooltip}
                </Tooltip>
              </Icon>
            )}
          </Description>
        </Label>
      </RadioWrapper>
    );
  }
}

const mapStateToProps = ({ translations }) => ({
  translations,
});

export default connect(mapStateToProps)(AccessoriesItem);
