import { API_PATHS, axiosInstance } from '../../../helpers/api';
import {
  appError,
  fetchTranslationsSuccess,
  fetchViewStart,
  fetchViewSuccess,
} from '../../app/actions';
import { clearResponse } from './clearResponse';
import { fetchTranslations } from '../../../helpers/translation';

const validTechnicalView = () => ({
  type: 'VALID_TECHNICAL_VIEW',
});

const changeOption = (id, type) => ({
  type: 'CHANGE_OPTION',
  payload: {
    id,
    type,
  },
});

const changeUg = id => ({
  type: 'CHANGE_UG',
  payload: {
    id,
  },
});

const changeUw = id => ({
  type: 'CHANGE_UW',
  payload: {
    id,
  },
});

const changePriceRange = value => ({
  type: 'CHANGE_PRICE_RANGE',
  payload: {
    value,
  },
});

const fetchView = pageId => async (dispatch, getState) => {
  try {
    dispatch(fetchViewStart());

    const responseData = {};

    const page = axiosInstance.get(API_PATHS.pages + pageId).then(response => response.data);

    const gas = axiosInstance.get(API_PATHS.gas).then(response => response.data);

    const paneExtras = axiosInstance.get(API_PATHS.paneExtras).then(response => response.data);

    const material = axiosInstance.get(API_PATHS.material).then(response => response.data);

    const axis = axiosInstance.get(API_PATHS.axis).then(response => response.data);

    const windowExtras = axiosInstance.get(API_PATHS.windowExtras).then(response => response.data);

    await Promise.all([page, gas, paneExtras, material, axis, windowExtras]).then(response => {
      responseData.page = response[0];
      responseData.gas = response[1];
      responseData.paneExtras = response[2];
      responseData.material = response[3];
      responseData.axis = response[4];
      responseData.windowExtras = response[5];
    });

    const translationsList = [
      { key: 'pane', value: 'Pane' },
      { key: 'window', value: 'Window' },
      { key: 'gas', value: 'Gas' },
      { key: 'extras', value: 'Extras' },
      { key: 'material', value: 'Material' },
      { key: 'pivotType', value: 'Pivot type' },
      { key: 'priceRange', value: 'Price range' },
    ];

    const translations = await fetchTranslations(translationsList);

    dispatch(fetchTranslationsSuccess(translations));
    dispatch(fetchViewSuccess('technical', clearResponse(responseData, getState)));
  } catch (error) {
    dispatch(appError());
  }
};

export { validTechnicalView, changeOption, changeUg, changeUw, changePriceRange, fetchView };
