import styled from 'styled-components';

const Title = styled.h1`
  display: none;
  text-transform: uppercase;
  font-size: 3.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};

  @media (min-width: ${({ theme }) => theme.breakpoints[theme.navbarBreakpoint]}) {
    display: block;
  }
`;

export default Title;
