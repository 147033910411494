import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { connect } from 'react-redux';
import PoductThumb from '../ProductThumb/ProductThumb';
import { Container, Section } from '../../layout';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import Title from '../Title/Title';
import { API_PATHS, axiosInstance } from '../../helpers/api';
import { getAccessoriesUniqIds } from '../../helpers/accessories';

const SliderItem = styled.div`
  padding: 0 16px;
`;

const SliderContainer = styled(Container)`
  position: relative;
`;

const SliderControls = styled.div`
  display: none;

  @media (min-width: 1366px) {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate3d(0, -50%, 0);
    display: flex;
    justify-content: space-between;

    > * {
      position: relative;

      :first-child {
        left: -32px;
      }

      :last-child {
        right: -32px;
      }
    }
  }
`;

class ProductSlider extends React.Component {
  state = {
    items: [],
  };

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidMount() {
    const { windows, accessories, onlyAccessories } = this.props;
    const sliderFormData = new FormData();

    if (!onlyAccessories && windows && windows.length > 0) {
      windows.forEach(item => {
        sliderFormData.append('windows[]', item);
      });
    }

    if (accessories && accessories.length > 0) {
      const accessoriesListIds = getAccessoriesUniqIds(accessories);

      accessoriesListIds.forEach(item => {
        sliderFormData.append('accessories[]', item);
      });
    }

    axiosInstance.post(API_PATHS.productsSlider, sliderFormData).then(response => {
      const items = response.data;
      const itemsList = [];
      for (const key in items) {
        const item = items[key];
        if (item.acf) {
          itemsList.push({
            id: item.id,
            title: item.acf[0].product_text,
            image: item.acf[0].product_image,
            url: item.acf[0].product_url,
          });
        }
      }

      this.setState({ items: itemsList });
    });
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    const { items } = this.state;

    const slickSettings = {
      dots: false,
      arrows: false,
      infinite: items.length > 4,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1366,
          settings: {
            infinite: items.length > 3,
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            infinite: items.length > 2,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      items.length > 0 && (
        <Section>
          <Container>
            <Title as="h2" margin>
              {this.props.translations.recommendedProducts}
            </Title>
          </Container>
          <SliderContainer>
            {items.length > 4 && (
              <SliderControls>
                <ButtonIcon icon="arrowLeft" large onClick={this.previous} />
                <ButtonIcon icon="arrowRight" large onClick={this.next} />
              </SliderControls>
            )}

            <Slider ref={c => (this.slider = c)} {...slickSettings}>
              {items.map(item => (
                <SliderItem key={item.id}>
                  <PoductThumb image={item.image} title={item.title} url={item.url} />
                </SliderItem>
              ))}
            </Slider>
          </SliderContainer>
        </Section>
      )
    );
  }
}

const mapStateToProps = ({ windows, accessories, translations, onlyAccessories }) => ({
  windows,
  accessories,
  translations,
  onlyAccessories,
});

export default connect(mapStateToProps)(ProductSlider);
