import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeStep as changeStepAction } from '../../store/app/actions';
import { Table, TableCol, TableContent, TableHeader, ButtonTable } from './SummaryTable.styled';
import {
  ContentBasic,
  ContentAdditional,
  ContentSizes,
  ContentFlashing,
  ContentTechnical,
} from './TableContents';

class SummaryTable extends Component {
  renderTableContent(view) {
    const { views, sizes, translations } = this.props;
    if (views.hasOwnProperty(view)) {
      switch (view) {
        case 'basic':
          return <ContentBasic data={views.basic} translations={translations} />;
        case 'additional':
          return <ContentAdditional data={views.additional} translations={translations} />;
        case 'sizes':
          return <ContentSizes sizes={sizes} translations={translations} />;
        case 'flashing':
          return <ContentFlashing data={views.flashing} translations={translations} />;
        case 'technical':
          return <ContentTechnical data={views.technical} translations={translations} />;
        default:
          return null;
      }
    }

    return null;
  }

  render() {
    const { appType, steps, changeStep } = this.props;
    return (
      <Table>
        {steps[appType].map((item, index) => (
          <TableCol key={item.id} large={appType === 'professionals' && index === 0}>
            <TableHeader>
              <ButtonTable icon="edit" onClick={() => changeStep(item.id)}>
                {item.title}
              </ButtonTable>
            </TableHeader>
            <TableContent>{this.renderTableContent(item.view)}</TableContent>
          </TableCol>
        ))}
      </Table>
    );
  }
}

const mapStateToProps = ({ appType, steps, views, sizes, translations }) => ({
  appType,
  steps,
  views,
  sizes,
  translations,
});

const mapDispatchToProps = dispatch => {
  return {
    changeStep: id => dispatch(changeStepAction(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SummaryTable);
