import groupBy from 'lodash/groupBy';
import SortBy from 'lodash/sortBy';
import { API_PATHS, axiosInstance } from '../../../helpers/api';
import { addWindows, appError, fetchTranslationsSuccess, fetchViewStart } from '../../app/actions';
import { clearResponse } from './clearResponse';
import { fetchTranslations } from '../../../helpers/translation';

const fetchAccessoriesSuccess = (data, accessories) => ({
  type: 'FETCH_ACCESSORIES_SUCCESS',
  payload: {
    data,
    accessories,
  },
});

const accessoriesCancel = () => ({
  type: 'ACCESSORIES_CANCEL',
});

const accessoriesComplete = () => ({
  type: 'ACCESSORIES_COMPLETE',
});

const changeAccessory = (size, id, color = null) => ({
  type: 'CHANGE_ACCESSORY',
  payload: {
    size,
    id,
    color,
  },
});

const removeAccessory = (size, id) => ({
  type: 'REMOVE_ACCESSORY',
  payload: {
    size,
    id,
  },
});

const changeFilter = name => ({
  type: 'CHANGE_FILTER',
  payload: {
    name,
  },
});

const getWindowAccessories = async id => {
  const accessoryData = new FormData();
  accessoryData.append('windowId', id);
  const accessoriesResponse = axiosInstance
    .post(API_PATHS.windowAccessory, accessoryData)
    .then(response => {
      return response.data.map(item => {
        const relatedAccessories = item.fields.related_accessories
          ? item.fields.related_accessories[0]
          : false;
        const colors = item.fields.colors
          ? item.fields.colors.map(color => {
              color.itemId = item.id.term_id;
              return color;
            })
          : null;
        return {
          id: item.id.term_id,
          name: item.fields.item_title,
          description: item.fields.item_description,
          image: item.fields.item_image,
          colors,
          checked: false,
          order: +item.fields.order_no,
          relatedAccessories,
        };
      });
    });

  return accessoriesResponse;
};

const fetchView = pageId => async (dispatch, getState) => {
  try {
    dispatch(fetchViewStart());

    const { onlyAccessories } = getState();

    if (onlyAccessories) {
      const windowsAccessories = await axiosInstance.post(API_PATHS.products).then(response => {
        return response.data;
      });

      if (windowsAccessories && windowsAccessories.length > 0) {
        dispatch(addWindows(windowsAccessories));
      }
    }

    let accessoriesList = null;
    const { windows } = getState();

    const pageData = await axiosInstance.get(API_PATHS.pages + pageId).then(response => {
      return response.data;
    });

    if (windows && windows.length > 0) {
      const allWindowsFormData = new FormData();

      windows.forEach(item => {
        allWindowsFormData.append('windows[]', item);
      });

      const windowsWithSizes = await axiosInstance
        .post(API_PATHS.windowsWithSizes, allWindowsFormData)
        .then(response => {
          return response.data;
        });

      const groupWindowsBySizeName = groupBy(windowsWithSizes, 'name');
      const groupWindowsFormat = SortBy(
        Object.keys(groupWindowsBySizeName).map(key => {
          return {
            name: key,
            dimension: groupWindowsBySizeName[key][0].dimension,
            windows: groupWindowsBySizeName[key].map(item => {
              return {
                windowId: item.sizeId,
                accessories: [],
              };
            }),
          };
        }),
        'name',
      );

      for (const windowItem of groupWindowsFormat) {
        const acc = windowItem.windows.map(el => getWindowAccessories(el.windowId));

        await Promise.all(acc).then(response => {
          windowItem.windows.forEach((el, index) => {
            el.accessories = response[index];
          });
        });
      }

      accessoriesList = groupWindowsFormat;
    }

    const translationsList = [
      { key: 'selectAColour', value: 'Select a colour' },
      { key: 'addAccessoriesToWindow', value: 'Add comfort accessories to window size:' },
    ];

    const translations = await fetchTranslations(translationsList);

    dispatch(fetchTranslationsSuccess(translations));
    dispatch(fetchAccessoriesSuccess(clearResponse(pageData), accessoriesList));
  } catch (error) {
    dispatch(appError());
  }
};

const removeAllAccessories = () => ({
  type: 'REMOVE_ALL_ACCESSORIES',
});

export {
  fetchView,
  fetchAccessoriesSuccess,
  accessoriesCancel,
  accessoriesComplete,
  changeAccessory,
  removeAccessory,
  changeFilter,
  removeAllAccessories,
};
