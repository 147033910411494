import React from 'react';

const ContentSizes = ({ sizes, translations }) => {
  const sizesFiltered = sizes.filter(item => item.id !== 'none');
  return (
    <>
      {sizesFiltered.map((item, index) => (
        <p key={`${index}${item.id}`}>
          <strong>{item.title} </strong> ({item.dimension.width} x {item.dimension.height})
          <strong style={{ textTransform: 'lowercase' }}> x{item.count}</strong>
        </p>
      ))}
      {(!sizesFiltered || sizesFiltered.length < 1) && <p>{translations.notSpecified}</p>}
    </>
  );
};

export default ContentSizes;
