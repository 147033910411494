import { API_PATHS, axiosInstance } from '../../../helpers/api';
import {
  appError,
  fetchTranslationsSuccess,
  fetchViewStart,
  fetchViewSuccess
} from '../../app/actions';
import { clearResponse } from './clearResponse';
import { fetchTranslations } from '../../../helpers/translation';

const fetchView = pageId => async dispatch => {
  try {
    dispatch(fetchViewStart());

    const pageData = await axiosInstance.get(API_PATHS.pages + pageId).then(response => {
      return response.data;
    });

    const sizesData = await axiosInstance.get(API_PATHS.sizes).then(response => {
      return response.data;
    });

    const translationsList = [
      { key: 'addWindows', value: 'Add windows' },
      { key: 'windowSize', value: 'Window size' },
      { key: 'amount', value: 'Amount' },
      { key: 'addAnotherWindow', value: 'Add another window' },
      { key: 'sizeCm', value: 'Size | cm' },
      { key: 'effectiveLightArea', value: 'Effective light area in m2' },
    ];

    const translations = await fetchTranslations(translationsList);

    dispatch(fetchTranslationsSuccess(translations));
    dispatch(fetchViewSuccess('sizes', clearResponse(pageData, sizesData)));
  } catch (error) {
    dispatch(appError());
  }
};

const addSize = () => ({
  type: 'ADD_SIZE',
});

const changeSize = (size, index) => ({
  type: 'CHANGE_SIZE',
  payload: {
    size,
    index,
  },
});

const changeSizeCount = (count, index) => ({
  type: 'CHANGE_SIZE_COUNT',
  payload: {
    count,
    index,
  },
});

const deleteSize = index => ({
  type: 'DELETE_SIZE',
  payload: {
    index,
  },
});

export { fetchView, addSize, changeSize, changeSizeCount, deleteSize };
