import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  padding: 0 ${({ theme }) => `${theme.gutter / 2}px`};

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    max-width: ${({ theme, fluid }) => (fluid ? '1920px' : theme.containers.xl)};
  }
`;

export default Container;
