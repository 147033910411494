import React, { Component } from 'react';
import { connect } from 'react-redux';
import uniqBy from 'lodash/unionBy';
import {
  TableRow,
  TableRowCol,
  TableItems,
  TableItemsRow,
  TableItem,
  ButtonTable,
  Table,
} from './SummaryAccessoriesTable.styled';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { goToAccessories as goToAccessoriesAction } from '../../store/app/actions';
import { removeAccessory as removeAccessoryAction } from '../../store/views/accessories/actions';
import Title from '../Title/Title';

class SummaryAccessoriesTable extends Component {
  getAccessories() {
    const { accessories, onlyAccessories, goToAccessories } = this.props;
    const formatedAccessories = [];

    accessories.forEach(group => {
      let accessoriesList = [];
      group.windows.forEach(window => {
        const checkedAccessories = window.accessories.filter(accessory => {
          if (accessory.colors) {
            const activeColor = accessory.colors.find(color => color.checked);
            if (activeColor) {
              return true;
            }

            return false;
          }

          return accessory.checked;
        });

        if (checkedAccessories) {
          accessoriesList.push(...checkedAccessories);
        }
      });

      if (accessoriesList.length > 0) {
        accessoriesList = uniqBy(accessoriesList, 'id');

        formatedAccessories.push({
          name: group.name,
          dimension: group.dimension,
          accessories: accessoriesList,
        });
      }
    });

    if (onlyAccessories && formatedAccessories.length === 0) {
      goToAccessories();
    }

    return formatedAccessories;
  }

  getCheckedColorName(colors) {
    if (colors) {
      const activeColor = colors.find(color => color.checked);

      if (activeColor) {
        return activeColor.color_name;
      }
    }

    return this.props.translations.notSpecified;
  }

  render() {
    const formatedAccessories = this.getAccessories();
    const { translations } = this.props;

    return (
      <>
        {formatedAccessories.length > 0 && (
          <>
            <Title as="h2" margin>
              {translations.accessoriesSummary}
            </Title>
            <Table>
              <TableRow header>
                <TableRowCol>
                  <TableItem header>{translations.windows}</TableItem>
                </TableRowCol>
                <TableRowCol>
                  <TableItems>
                    <TableItemsRow>
                      <TableItem header nameCol>
                        {translations.selectedAccessories}
                      </TableItem>
                      <TableItem header>{translations.colour}</TableItem>
                      <TableItem header deleteCol>
                        {translations.delete}
                      </TableItem>
                    </TableItemsRow>
                  </TableItems>
                </TableRowCol>
              </TableRow>
              {formatedAccessories.map(group => (
                <TableRow key={group.name}>
                  <TableRowCol>
                    <TableItem>
                      <ButtonTable link icon="edit" onClick={() => this.props.goToAccessories()}>
                        <strong>{group.name}</strong>{' '}
                        {`\u00A0(${group.dimension.width} x ${group.dimension.height})`}
                      </ButtonTable>
                    </TableItem>
                  </TableRowCol>
                  <TableRowCol>
                    <TableItems>
                      {group.accessories.map(accessory => (
                        <TableItemsRow key={accessory.name}>
                          <TableItem nameCol>
                            <strong>{accessory.name}</strong>
                          </TableItem>
                          <TableItem>{this.getCheckedColorName(accessory.colors)}</TableItem>
                          <TableItem deleteCol>
                            <ButtonIcon
                              icon="trash"
                              large
                              onClick={() => this.props.removeAccessory(group.name, accessory.id)}
                            />
                          </TableItem>
                        </TableItemsRow>
                      ))}
                    </TableItems>
                  </TableRowCol>
                </TableRow>
              ))}
            </Table>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = ({ accessories, translations, onlyAccessories }) => ({
  accessories,
  translations,
  onlyAccessories,
});

const mapDispatchToProps = dispatch => {
  return {
    goToAccessories: () => dispatch(goToAccessoriesAction()),
    removeAccessory: (size, id) => dispatch(removeAccessoryAction(size, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SummaryAccessoriesTable);
