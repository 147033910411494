import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import HeaderDescription from '../HeaderDescription/HeaderDescription';
import Title from '../Title/Title';

class HeaderPage extends Component {
  render() {
    const { activePath, views, viewLoading, windows, onlyAccessories } = this.props;
    const activeView = activePath.split('/')[1];

    let { title } = views[activeView].header;
    let { description } = views[activeView].header;

    if (!onlyAccessories && activeView === 'summary' && (windows && windows.length === 0)) {
      title = views.summary.headerNoResults.title;
      description = views.summary.headerNoResults.description;
    }

    if (onlyAccessories && activeView === 'summary') {
      title = views.summary.headerAccessories.title;
      description = views.summary.headerAccessories.description;
    }

    if (onlyAccessories && activeView === 'accessories') {
      title = views.accessories.headerAccessories.title;
      description = views.accessories.headerAccessories.description;
    }

    return (
      !viewLoading && (
        <Header>
          {title && (
            <Title as="h2" primary>
              {title}
            </Title>
          )}

          {description && (
            <HeaderDescription dangerouslySetInnerHTML={{ __html: `${description}` }} />
          )}
        </Header>
      )
    );
  }
}

const mapStateToProps = ({ activePath, views, viewLoading, windows, onlyAccessories }) => ({
  activePath,
  views,
  viewLoading,
  windows,
  onlyAccessories,
});

export default connect(mapStateToProps)(HeaderPage);
