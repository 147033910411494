import styled from 'styled-components';

const Section = styled.div`
  margin: 48px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin: 64px 0;
  }
`;

export default Section;
