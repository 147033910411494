import { setCheckedById, setCheckedOneById } from '../../../helpers/store';

const reducerChangeRoomType = (state, action) => {
  const roomTypes = setCheckedOneById(state.views.basic.roomTypes, action.payload.id);

  return {
    ...state,
    views: {
      ...state.views,
      basic: {
        ...state.views.basic,
        roomTypes,
      },
    },
  };
};

const reducerChangeRequirement = (state, action) => {
  const requirements = setCheckedById(state.views.basic.requirements, action.payload.id);

  return {
    ...state,
    views: {
      ...state.views,
      basic: {
        ...state.views.basic,
        requirements,
      },
    },
  };
};

const reducerValidBasicView = state => {
  const roomTypesValid = state.views.basic.roomTypes.find(item => item.checked);

  return {
    ...state,
    stepValid: !!roomTypesValid,
  };
};

export { reducerChangeRoomType, reducerChangeRequirement, reducerValidBasicView };
