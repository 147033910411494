import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  color: #fff;

  > div > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 12px;
    height: 8px;
    margin-left: 8px;
    fill: #fff;
    pointer-events: none;
  }
`;

export default Button;
