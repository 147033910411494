import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  fetchView as fetchViewAction,
  addSize as addSizeAction,
  changeSize as changeSizeAction,
  changeSizeCount as changeSizeCountAction,
  deleteSize as deleteSizeAction,
} from '../../store/views/sizes/actions';

import { Section, Container, Row, LoaderView } from '../../layout';
import Title from '../../components/Title/Title';
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import InputNumber from '../../components/InputNumber/InputNumber';
import {
  SizesCol,
  InfoBox,
  InfoBoxCol,
  InfoTitle,
  InfoParagraph,
  Calc,
  Table,
  TableTh,
  TableTr,
  TableTd,
  Windows,
  WindowItem,
  WindowItemCol,
  WindowItemLabel,
  WindowItemContent,
} from './Sizes.styled';

class Sizes extends Component {
  state = {
    calc: 0.0,
  };

  componentDidMount() {
    if (!this.props.viewData.fetched) {
      const pageId = this.props.match.params.id;
      this.props.fetchView(pageId);
      this.props.addSize();
    }
  }

  handleSizeChange = (event, index) => {
    const size = this.props.viewData.sizesList.find(el => el.id === event.target.value);
    if (size) {
      this.props.changeSize(size, index);
    } else {
      this.props.changeSize(
        {
          id: 'none',
          title: this.props.translations.selectFromList,
          count: 1,
        },
        index,
      );
    }
  };

  handleCountChange = (count, index) => {
    if (count > 0) {
      this.props.changeSizeCount(count, index);
    }
  };

  handleCalc = event => {
    const { value } = event.target;
    if (!Number.isNaN(value))
      this.setState(() => ({
        calc: (value * 15) / 100,
      }));
  };

  render() {
    const { viewLoading, translations } = this.props;
    const { info, sizesList } = this.props.viewData;

    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <Section>
            <Container>
              <Row>
                <SizesCol>
                  <Title margin left>
                    {translations.addWindows}
                  </Title>
                  {this.props.sizes.map((item, index) => (
                    <Windows key={`${index}_${item.id}`}>
                      <WindowItem>
                        <WindowItemCol>
                          <WindowItemLabel>{translations.windowSize}</WindowItemLabel>
                          <WindowItemContent>
                            {sizesList && (
                              <Select
                                options={sizesList}
                                value={item.id}
                                onChange={event => this.handleSizeChange(event, index)}
                              />
                            )}
                          </WindowItemContent>
                        </WindowItemCol>
                        <WindowItemCol>
                          <WindowItemLabel>{translations.amount}</WindowItemLabel>
                          <WindowItemContent amount>
                            <InputNumber
                              value={item.count}
                              onChange={count => this.handleCountChange(count, index)}
                            />
                            <ButtonIcon
                              icon="trash"
                              large
                              onClick={() => this.props.deleteSize(index)}
                            />
                          </WindowItemContent>
                        </WindowItemCol>
                      </WindowItem>
                    </Windows>
                  ))}
                  <Button link onClick={() => this.props.addSize()}>
                    + {translations.addAnotherWindow}
                  </Button>
                </SizesCol>

                <SizesCol>
                  <InfoBox>
                    <InfoBoxCol>
                      {info.map((item, index) =>
                        item.contentType === 'title' ? (
                          <InfoTitle
                            key={`info${index + 1}`}
                            dangerouslySetInnerHTML={{ __html: `${item.content}` }}
                          />
                        ) : (
                          <InfoParagraph
                            key={`info${index + 1}`}
                            dangerouslySetInnerHTML={{ __html: `${item.content}` }}
                          />
                        ),
                      )}
                      <Calc>
                        <input type="number" min="1" onChange={this.handleCalc} />
                        <p>
                          m<sup>2</sup>x 15% = <span>{this.state.calc.toPrecision(3)}</span>
                        </p>
                      </Calc>
                    </InfoBoxCol>
                    <InfoBoxCol>
                      <Table>
                        <thead>
                          <tr>
                            <TableTh>{translations.sizeCm}</TableTh>
                            <TableTh>{translations.effectiveLightArea}</TableTh>
                          </tr>
                        </thead>
                        <tbody>
                          {sizesList
                            .filter(item => item.value !== 'none')
                            .map((item, index) => (
                              <TableTr key={`${item.title}-${index + 1}`}>
                                <TableTd>
                                  <strong>{item.title}</strong>
                                  {` (${item.dimension.width} x ${item.dimension.height})`}
                                </TableTd>
                                <TableTd>
                                  <strong>{item.field}</strong>
                                </TableTd>
                              </TableTr>
                            ))}
                        </tbody>
                      </Table>
                    </InfoBoxCol>
                  </InfoBox>
                </SizesCol>
              </Row>
            </Container>
          </Section>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ viewLoading, sizes, views: { sizes: viewData }, translations }) => ({
  viewLoading,
  viewData,
  sizes,
  translations,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchView: pageId => dispatch(fetchViewAction(pageId)),
    addSize: () => dispatch(addSizeAction()),
    deleteSize: index => dispatch(deleteSizeAction(index)),
    changeSize: (size, index) => dispatch(changeSizeAction(size, index)),
    changeSizeCount: (count, index) => dispatch(changeSizeCountAction(count, index)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sizes);
