import styled, { css } from 'styled-components';
import Button from '../Button/Button';

const Table = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    border: 1px solid #000;
  }
`;

const TableRow = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: flex;
  }

  ${({ header }) =>
    header &&
    css`
      display: none;
      background-color: #000;
      @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        display: flex;
      }
    `}
`;

const TableRowCol = styled.div`
  flex: 1;

  &:first-of-type {
    flex: 0 0 25%;
  }
`;

const TableItemsRow = styled.div`
  display: flex;
`;

const TableItems = styled.div``;

const TableItem = styled.div`
  height: 100%;
  flex: 1;
  min-height: 30px;
  display: flex;
  align-items: center;
  font-size: 1.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.8rem;
    border: 1px solid #000;
    padding: 4px 12px;
    min-height: 60px;
  }
  
  ${({ nameCol }) =>
    nameCol &&
    css`
      flex: 2;
    `}

  ${({ deleteCol }) =>
    deleteCol &&
    css`
      flex: 0 0 40px;
      justify-content: center;

      @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        flex-basis: 140px;
      }
    `}

  ${({ header }) =>
    header &&
    css`
      font-size: 2rem;
      font-weight: ${({ theme }) => theme.fontWeight.black};
      line-height: 1;
      color: #fff;
      text-transform: uppercase;
    `}
`;

const ButtonTable = styled(Button)`
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.primary};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 2rem;

  :hover {
    background: none;
    border: none;
  }

  svg {
    fill: ${({ theme }) => theme.primary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    color: #000;
  }
`;

export { Table, TableRow, TableRowCol, TableItems, TableItemsRow, TableItem, ButtonTable };
