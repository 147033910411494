import styled from 'styled-components';

const SummaryButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0 -12px;
  }

  button {
    width: 100%;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: auto;
      min-width: 280px;
    }
  }
`;

const SummaryButtonsCol = styled.div`
  flex: 0 0 100%;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex: 0 0 auto;
    margin: 0 12px 16px;
  }
`;

export { SummaryButtons, SummaryButtonsCol };
