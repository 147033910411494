import React from 'react';

const ContentFlashing = ({ data: { flashings, roofingTypes }, translations }) => {
  const flashingsFiltered = flashings
    .filter(item => item.checked)
    .map(item => item.title)
    .join(', ');
  const roofingTypesFiltered = roofingTypes
    .filter(item => item.checked)
    .map(item => item.title)
    .join(', ');

  return (
    <>
      {flashingsFiltered && (
        <p>
          <strong>{translations.flashing}: </strong> {flashingsFiltered}
        </p>
      )}
      {roofingTypesFiltered && (
        <p>
          <strong>{translations.roofingType}: </strong> {roofingTypesFiltered}
        </p>
      )}
      {!flashingsFiltered && !roofingTypesFiltered && <p>{translations.notSpecified}</p>}
    </>
  );
};

export default ContentFlashing;
