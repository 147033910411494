import logo from './icons/logo.svg';
import iconImproved from './icons/improved_comfort.svg';
import iconEnergy from './icons/energy_efficiency.svg';
import iconEasy from './icons/easy_maintenance.svg';
import iconSafety from './icons/safety.svg';
import iconMeans from './icons/means_of_escape.svg';
import iconLight from './icons/light_and_fresh_air.svg';
import iconReset from './icons/start_over.svg';
import iconEdit from './icons/edit.svg';
import iconTrash from './icons/trash_bin.svg';
import iconPdf from './icons/generate_pdf.svg';
import iconAddCircle from './icons/add_accessories.svg';
import iconArrowLeft from './icons/arrow_left.svg';
import iconArrowRight from './icons/arrow_right.svg';
import iconArrowDown from './icons/arrow_down.svg';
import iconTooltip from './icons/tooltip.svg';
import iconSuccess from './icons/ok.svg';
import iconAdd from './icons/add.svg';
import iconRemove from './icons/remove.svg';
import iconPalette from './icons/paint_palette.svg';
import iconClose from './icons/close.svg';
import imageTechnical from './images/technicalImage.jpg';
import imageTechnicalPane from './images/technicalPane.png';
import imageTechnicalWindow from './images/technicalWindow.png';

export { imageTechnical, imageTechnicalPane, imageTechnicalWindow };

export const iconList = {
  logo,
  improved: iconImproved,
  energy: iconEnergy,
  easy: iconEasy,
  safety: iconSafety,
  means: iconMeans,
  light: iconLight,
  reset: iconReset,
  edit: iconEdit,
  trash: iconTrash,
  pdf: iconPdf,
  addCircle: iconAddCircle,
  arrowLeft: iconArrowLeft,
  arrowRight: iconArrowRight,
  arrowDown: iconArrowDown,
  tooltip: iconTooltip,
  success: iconSuccess,
  add: iconAdd,
  remove: iconRemove,
  palette: iconPalette,
  close: iconClose,
};
