import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Hamburger from './Hamburger/Hamburger';

const ControlsWrapper = styled.div`
  display: flex;
  margin-left: 32px;
  align-items: center;
`;

const Controls = ({ open, handleHamburgerClickFn }) => (
  <ControlsWrapper>
    <Hamburger open={open} handleHamburgerClickFn={handleHamburgerClickFn} />
  </ControlsWrapper>
);

Controls.propTypes = {
  open: PropTypes.bool.isRequired,
  handleHamburgerClickFn: PropTypes.func.isRequired,
};

export default Controls;
