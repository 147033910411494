import React, { Component } from 'react';
import styled from 'styled-components';
import Input from '../Input/Input';

const InputWrapper = styled.div`
  position: relative;
`;

const Controls = styled.div`
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translate3d(0, -50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 44px;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    right: 3px;
    height: 48px;
  }
`;

const Control = styled.button`
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  line-height: 1;
`;

class InputNumber extends Component {
  handleIncrement() {
    this.props.onChange(this.props.value + 1, this.props.index);
  }

  handleDecrement() {
    this.props.onChange(this.props.value - 1, this.props.index);
  }

  render() {
    return (
      <InputWrapper>
        <Input type="number" value={this.props.value} disabled />
        <Controls>
          <Control onClick={() => this.handleIncrement()}>+</Control>
          <Control onClick={() => this.handleDecrement()}>-</Control>
        </Controls>
      </InputWrapper>
    );
  }
}

export default InputNumber;
