import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const HTMLHead = ({ lang, title, description }) => (
  <Helmet>
    <html lang={lang} />
    <title>{title}</title>
    <meta name="description" content={description} />
  </Helmet>
);

HTMLHead.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

HTMLHead.defaultProps = {
  lang: 'en',
  title: '',
  description: '',
};

export default HTMLHead;
