import React from 'react';
import styled from 'styled-components';

const InputField = styled.input`
  font-size: 1.4rem;
  line-height: 20px;
  height: 48px;
  border: 2px solid #000;
  box-shadow: none;
  background: none;
  appearance: none;
  width: 100%;
  border-radius: 0;
  padding-left: 16px;
  padding-right: 46px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: #000;
  
  ::placeholder {
    color: #000;
  }

  :focus {
    outline: none !important;
    border-color: ${({ theme }) => theme.primary}; !important;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.8rem;
    height: ${({ small }) => (small ? '48px' : '54px')};
  }
`;

const Error = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 1.2rem;
  color: #f00;
`;

const Input = ({ error, className, ...props }) => (
  <div className={className}>
    <InputField {...props} />
    {error && <Error>{error}</Error>}
  </div>
);

export default Input;
