import styled from 'styled-components';

const Col = styled.div`
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  padding-left: ${({ theme }) => `${theme.gutter / 2}px`};
  padding-right: ${({ theme }) => `${theme.gutter / 2}px`};
`;

export default Col;
