const initialState = {
  loading: false,
  message: '',
  inquiry: '',
  windowItems: false,
  fields: {
    'person-name': {
      value: '',
      error: '',
    },
    'person-surname': {
      value: '',
      error: '',
    },
    'person-email': {
      value: '',
      error: '',
    },
    'person-phone': {
      value: '',
      error: '',
    },
    'person-province': {
      value: '',
      error: '',
    },
    inquiry: {
      value: '',
      error: '',
    },
    subject: {
      value: '',
      error: '',
    },
    region: {
      value: '',
      error: '',
    },
    message: {
      value: '',
      error: '',
    },
  },
};

export default initialState;
