import { API_PATHS, axiosInstance } from '../../helpers/api';
import { clearResponse } from './clearResponse';
import { fetchTranslations } from '../../helpers/translation';

const fetchAppStart = () => ({
  type: 'FETCH_APP_START',
});

const fetchAppSuccess = data => ({
  type: 'FETCH_APP_SUCCESS',
  payload: {
    data,
  },
});

const fetchTranslationsSuccess = data => ({
  type: 'FETCH_TRANSLATIONS_SUCCESS',
  payload: {
    data,
  },
});

const appError = () => ({
  type: 'APP_ERROR',
});

const resetApp = () => ({
  type: 'RESET_APP',
});

const setAppType = type => {
  return {
    type: 'SET_APP_TYPE',
    payload: {
      type,
    },
  };
};

const changeStep = id => {
  return {
    type: 'CHANGE_STEP',
    payload: {
      id,
    },
  };
};

const nextStep = () => ({
  type: 'NEXT_STEP',
});

const goToSummary = () => ({
  type: 'GO_TO_SUMMARY',
});

const goToAccessories = () => ({
  type: 'GO_TO_ACCESSORIES',
});

const fetchViewStart = () => ({
  type: 'FETCH_VIEW_START',
});

const fetchViewSuccess = (view, data) => ({
  type: 'FETCH_VIEW_SUCCESS',
  payload: {
    view,
    data,
  },
});

const fetchApp = () => async dispatch => {
  dispatch(fetchAppStart());

  try {
    const pageData = await axiosInstance.get(API_PATHS.settings).then(response => {
      return clearResponse(response.data);
    });

    const translationsList = [
      { key: 'selectFromList', value: 'Select from the list...' },
      { key: 'startOver', value: 'Start over' },
      { key: 'nextStep', value: 'Next step' },
      { key: 'goToSummary', value: 'Go to summary' },
      { key: 'complete', value: 'Complete' },
      { key: 'cancelAndGoBackToSummary', value: 'Cancel and go back to summary' },
      { key: 'whatsNext', value: 'What&#39;s next?' },
      { key: 'whereToBuy', value: 'Where to buy' },
    ];

    const translations = await fetchTranslations(translationsList);

    dispatch(fetchTranslationsSuccess(translations));
    dispatch(fetchAppSuccess(pageData));
  } catch (e) {
    dispatch(appError());
  }
};

const addWindows = windows => {
  return {
    type: 'ADD_WINDOWS',
    payload: {
      windows,
    },
  };
};

export {
  fetchApp,
  appError,
  resetApp,
  setAppType,
  changeStep,
  nextStep,
  goToSummary,
  goToAccessories,
  fetchViewStart,
  fetchViewSuccess,
  fetchTranslationsSuccess,
  addWindows,
};
