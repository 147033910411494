import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactSVG from 'react-svg';
import { iconList } from '../../../assets';

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  font-size: 24px;
  font-weight: 900;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  color: #fff;

  svg {
    width: 19px;
    height: 16px;
    fill: #fff;
    stroke: #fff;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const Title = styled.p`
  display: none;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  font-size: 1.6rem;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: block;
  }
`;

const TabWrapper = styled.div`
  position: relative;
  z-index: 2;

  ${({ active }) =>
    active &&
    css`
      ${Title} {
        color: ${({ theme }) => theme.primary};
      }

      ${Button} {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: 2px solid ${({ theme }) => theme.primary};
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      ${Title} {
        color: ${({ theme }) => theme.disabled};
      }

      ${Button} {
        position: relative;
        background-color: ${({ theme }) => theme.disabled};

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: 2px solid ${({ theme }) => theme.disabled};
        }
      }
    `}
`;

const TabHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin: 0 auto 8px;
  background-color: ${({ theme }) => theme.gray100};

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 80px;
  }
`;

const Tab = ({ id, index, title, handleClickTab, active, success, disabled }) => (
  <TabWrapper active={active} disabled={disabled}>
    <TabHeader>
      <Button onClick={() => handleClickTab(id)} disabled={disabled}>
        {success ? <ReactSVG src={iconList.success} /> : index + 1}
      </Button>
    </TabHeader>
    <Title>{title}</Title>
  </TabWrapper>
);

Tab.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  handleClickTab: PropTypes.func.isRequired,
  active: PropTypes.bool,
  success: PropTypes.bool,
};

Tab.defaultProps = {
  active: false,
  success: false,
};

export default Tab;
