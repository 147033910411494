import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Line from './Line';

const HamburgerWrapper = styled.button`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 12px;
  padding: 14px 12px;
  background-color: ${({ theme }) => theme.primary};

  ${({ open }) =>
    open &&
    css`
      ${Line} {
        &:nth-of-type(1) {
          top: 50%;
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          top: 50%;
          bottom: auto;
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        }
      }
    `}
`;

const Box = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Hamburger = ({ open, handleHamburgerClickFn }) => (
  <HamburgerWrapper open={open} onClick={handleHamburgerClickFn}>
    <Box>
      <Line />
      <Line />
      <Line />
    </Box>
  </HamburgerWrapper>
);

Hamburger.propTypes = {
  open: PropTypes.bool.isRequired,
  handleHamburgerClickFn: PropTypes.func.isRequired,
};

export default Hamburger;
