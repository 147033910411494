import styled, { css } from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${({ theme }) => `-${theme.gutter / 2}px`};
  margin-right: ${({ theme }) => `-${theme.gutter / 2}px`};

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}
`;

export default Row;
