import styled, { css } from 'styled-components';
import Title from '../../components/Title/Title';

export const RangeBox = styled.div`
  margin: 32px auto 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 900px;
  }
`;

export const RangeBoxTitle = styled(Title)`
  margin-bottom: 16px;
`;

export const RangeItem = styled.div`
  display: flex;
  align-items: center;
`;

export const RangeLabel = styled.p`
  display: none;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }

  ${({ right }) =>
    right &&
    css`
      text-align: right;
    `}
`;

export const RangeSlider = styled.div`
  display: flex;
  flex: 1;
  height: 22px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 48px;
  }
`;

export const marksStyle = {
  fontSize: '1.4rem',
  fontWeight: 700,
  color: '#000',
  marginLeft: 0,
  marginTop: '4px',
};

export const trackStyle = {
  backgroundColor: '#f18500',
  height: '12px',
  borderRadius: '12px',
  marginLeft: '1px',
};

export const handleStyle = {
  borderColor: '#000',
  height: 24,
  width: 24,
  marginTop: -6,
  backgroundColor: '#000',
};

export const railStyle = { backgroundColor: '#f18500', height: '12px' };

export const dotStyle = {
  background: '#fff',
  width: '1px',
  height: '10px',
  top: '1px',
  bottom: '1px',
  borderRadius: 0,
  marginLeft: 0,
  border: 'none',
};
