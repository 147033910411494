import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Brand from '../Brand/Brand';
import NavList from './NavList/NavList';

const ContentWrapper = styled.div`
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 100%;
  padding: 0 16px 16px;
  z-index: ${({ theme }) => theme.zIndex.navContent};
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.2s ease;
  background-color: ${({ theme }) => theme.primary};

  ${({ open }) =>
    open &&
    css`
      transform: translate3d(0, 0, 0);
    `}
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 80px;

  @media (min-width: ${({ theme }) => theme.breakpoints[theme.navbarBreakpoint]}) {
    height: 100px;
  }
`;

const Content = ({ open, items, dropdownClickFn }) => (
  <ContentWrapper open={open}>
    <ContentHeader>
      <Brand />
    </ContentHeader>
    <NavList items={items} dropdownClickFn={dropdownClickFn} />
  </ContentWrapper>
);

Content.propTypes = {
  open: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  dropdownClickFn: PropTypes.func.isRequired,
};

export default Content;
