import React from 'react';

const ContentAdditional = ({ data: { ranges }, translations }) => (
  <>
    {ranges.map(range => (
      <p key={range.id}>
        <strong>{range.title}: </strong> {range.value}/{range.max}
      </p>
    ))}

    {(!ranges || ranges.length < 1) && <p>{translations.notSpecified}</p>}
  </>
);

export default ContentAdditional;
