import React from 'react';
import styled from 'styled-components';
import ReactSVG from 'react-svg';
import { iconList } from '../../assets';
import Title from '../Title/Title';
import Button from '../Button/Button';

const ErrorWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const LoaderBox = styled.div`
  position: relative;
  text-align: center;
`;

const LoaderLogo = styled.div`
  display: inline-block;
  margin-bottom: 24px;

  svg {
    width: 51px;
    height: 60px;
  }
`;

const Error = () => (
  <ErrorWrapper>
    <LoaderBox>
      <LoaderLogo>
        <ReactSVG src={iconList.logo} />
      </LoaderLogo>
      <Title small style={{ marginBottom: '24px' }}>
        Something went wrong.. :(
      </Title>
      <Button as="a" href="/" icon="reset" link>
        go back to homepage
      </Button>
    </LoaderBox>
  </ErrorWrapper>
);

export default Error;
