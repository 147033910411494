import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import {
  fetchView as fetchViewAction,
  changeRange as changeRangeAction,
} from '../../store/views/additional/actions';

import { Section, Container, LoaderView } from '../../layout';
import Title from '../../components/Title/Title';
import {
  RangeBox,
  RangeBoxTitle,
  RangeItem,
  RangeLabel,
  RangeSlider,
  marksStyle,
  trackStyle,
  handleStyle,
  railStyle,
  dotStyle,
} from './Additional.styled';
import 'rc-slider/assets/index.css';

const marks = {
  1: { style: marksStyle, label: <span>1</span> },
  2: { style: marksStyle, label: <span>2</span> },
  3: { style: marksStyle, label: <span>3</span> },
  4: { style: marksStyle, label: <span>4</span> },
  5: { style: marksStyle, label: <span>5</span> },
};

class Additional extends Component {
  componentDidMount() {
    if (!this.props.views.additional.fetched) {
      const pageId = this.props.match.params.id;
      this.props.fetchView(pageId);
    }
  }

  handleRangeChange = (value, name) => {
    this.props.changeRange(name, value);
  };

  render() {
    const {
      viewLoading,
      translations,
      views: {
        additional: { ranges },
      },
    } = this.props;

    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <Section>
            <Container>
              <Title as="h2" margin>
                {translations.additionalTitle}
              </Title>
              {ranges.map(el => (
                <RangeBox key={el.id}>
                  <RangeBoxTitle as="h3" small>
                    {el.title}
                  </RangeBoxTitle>
                  <RangeItem>
                    <RangeLabel>{translations.notImportant}</RangeLabel>
                    <RangeSlider>
                      <Slider
                        min={+el.min}
                        max={+el.max}
                        step={+el.step}
                        defaultValue={el.value}
                        onChange={value => this.handleRangeChange(value, el.id)}
                        marks={marks}
                        trackStyle={trackStyle}
                        handleStyle={handleStyle}
                        railStyle={railStyle}
                        dotStyle={dotStyle}
                      />
                    </RangeSlider>
                    <RangeLabel right>{translations.veryImportant}</RangeLabel>
                  </RangeItem>
                </RangeBox>
              ))}
            </Container>
          </Section>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ viewLoading, views, translations }) => ({
  viewLoading,
  views,
  translations,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchView: pageId => dispatch(fetchViewAction(pageId)),
    changeRange: (name, value) => dispatch(changeRangeAction(name, value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Additional);
