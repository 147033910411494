import { appError, fetchTranslationsSuccess, fetchViewStart } from '../../app/actions';
import { API_PATHS, axiosInstance } from '../../../helpers/api';
import { clearResponse } from './clearResponse';
import { fetchTranslations } from '../../../helpers/translation';

const fetchSummarySuccess = (data, windows) => ({
  type: 'FETCH_SUMMARY_SUCCESS',
  payload: {
    data,
    windows,
  },
});

const fetchSummaryAccessoriesSuccess = data => ({
  type: 'FETCH_SUMMARY_ACCESSORIES_SUCCESS',
  payload: {
    data,
  },
});

const fetchView = pageId => async (dispatch, getState) => {
  try {
    dispatch(fetchViewStart());

    const { appType, views, sizes, onlyAccessories } = getState();
    const formData = new FormData();
    let windows = [];

    const pageData = await axiosInstance.get(API_PATHS.pages + pageId).then(response => {
      return response.data;
    });

    if (!onlyAccessories) {
      sizes
        .filter(item => item.id !== 'none')
        .forEach(item => {
          formData.append('size[]', item.id);
        });
      views.flashing.roofingTypes
        .filter(item => item.checked)
        .forEach(item => {
          formData.append('roofing_type', item.id);
        });

      if (appType === 'home') {
        views.basic.roomTypes
          .filter(item => item.checked)
          .forEach(item => {
            formData.append('roomTypes[]', item.id);
          });
        views.basic.requirements
          .filter(item => item.checked)
          .forEach(item => {
            formData.append('requirements[]', item.id);
          });
      } else {
        views.technical.gas.items
          .filter(item => item.checked)
          .forEach(item => {
            formData.append('gas[]', item.title);
          });
        views.technical.paneExtras.items
          .filter(item => item.checked)
          .forEach(item => {
            formData.append('pane_extras[]', item.id);
          });
        views.technical.material.items
          .filter(item => item.checked)
          .forEach(item => {
            formData.append('material[]', item.title);
          });
        views.technical.axis.items
          .filter(item => item.checked)
          .forEach(item => {
            formData.append('axis[]', item.title);
          });
        views.technical.windowExtras.items
          .filter(item => item.checked)
          .forEach(item => {
            formData.append('window_extras[]', item.id);
          });
        views.flashing.flashings
          .filter(item => item.checked)
          .forEach(item => {
            formData.append('slate_type', item.id);
          });
        views.technical.ug
          .filter(item => item.checked)
          .forEach(item => {
            const value = item.id.split('-').map(el => Number.parseFloat(el.trim()));

            formData.append('ug[min]', value[0]);
            if (value[1]) {
              formData.append('ug[max]', value[1]);
            }
          });
        views.technical.uw
          .filter(item => item.checked)
          .forEach(item => {
            const value = item.id.split('-').map(el => Number.parseFloat(el.trim()));

            formData.append('heat_factor[min]', value[0]);
            if (value[1]) {
              formData.append('heat_factor[max]', value[1]);
            }
          });

        formData.append('price_min', views.technical.priceRange.value[0]);
        formData.append('price_max', views.technical.priceRange.value[1]);
      }

      windows = await axiosInstance.post(API_PATHS.products, formData).then(response => {
        return response.data;
      });
    }

    const translationsList = [
      { key: 'windowsSummary', value: 'Windows summary' },
      { key: 'addAccessories', value: 'Add accessories' },
      { key: 'generatePdf', value: 'Generate PDF' },
      { key: 'recommendedProducts', value: 'Recommended products' },
      { key: 'accessoriesSummary', value: 'Accessories summary' },
      { key: 'windows', value: 'Windows' },
      { key: 'selectedAccessories', value: 'Selected accessories' },
      { key: 'colour', value: 'Colour' },
      { key: 'delete', value: 'Delete' },
      { key: 'notSpecified', value: 'not specified' },
      { key: 'firstName', value: 'First name' },
      { key: 'surname', value: 'Surname' },
      { key: 'email', value: 'E-mail address' },
      { key: 'phone', value: 'Phone number' },
      { key: 'province', value: 'Województwo' },
      { key: 'typeOfContact', value: 'Which profession describes you best?' },
      { key: 'typeOfRegion', value: 'Region' },
      { key: 'writeYourMessage', value: 'Write your message' },
      { key: 'flashing', value: 'Flashing' },
    ];

    const translations = await fetchTranslations(translationsList);

    dispatch(fetchTranslationsSuccess(translations));

    if (onlyAccessories) {
      dispatch(fetchSummaryAccessoriesSuccess(clearResponse(pageData)));
    } else {
      if (typeof window.dataLayer !== 'undefined' && Array.isArray(window.dataLayer)) {
        window.dataLayer.push({
          appType,
        });
      }
      dispatch(fetchSummarySuccess(clearResponse(pageData), windows));
    }
  } catch (error) {
    dispatch(appError());
  }
};

export { fetchView, fetchSummarySuccess };
