import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Range } from 'rc-slider';
import {
  fetchView as fetchViewAction,
  changeOption as changeOptionAction,
  changeUg as changeUgAction,
  changeUw as changeUwAction,
  changePriceRange as changePriceRangeAction,
  validTechnicalView as validTechnicalViewAction,
} from '../../store/views/technical/actions';
import { Section, Container, Row, LoaderView } from '../../layout';
import Select from '../../components/Select/Select';
import Radio from '../../components/Radio/Radio';
import { imageTechnical } from '../../assets';
import {
  TechnicalTitle,
  TechnicalCol,
  TechnicalImg,
  TechnicalGroup,
  TechnicalOption,
  TechnicalLabel,
  TechnicalItems,
  TechnicalItemsCol,
  PriceRangeLabels,
  PriceRangeLabel,
  trackStyle,
  handleStyle,
  railStyle,
} from './Technical.styled';
import Title from '../../components/Title/Title';

class Technical extends Component {
  constructor(props) {
    super(props);

    this.rangeMin = React.createRef();
    this.rangeMax = React.createRef();
  }

  componentDidMount() {
    if (!this.props.views.technical.fetched) {
      const pageId = this.props.match.params.id;
      this.props.fetchView(pageId);
    }
    this.props.validTechnicalView();
  }

  handleUgChange = event => {
    this.props.changeUg(event.target.value);
    this.props.validTechnicalView();
  };

  handleUwChange = event => {
    this.props.changeUw(event.target.value);
    this.props.validTechnicalView();
  };

  handlePriceChange = value => {
    this.rangeMin.current.textContent = value[0];
    this.rangeMax.current.textContent = value[1];
  };

  getCheckedId = list => {
    const checked = list.find(item => item.checked);
    return checked ? checked.id : 'none';
  };

  renderItemsChoose(option) {
    return (
      <TechnicalOption>
        <TechnicalLabel>{option.label}</TechnicalLabel>
        <TechnicalItems>
          {option.items.map(item => (
            <TechnicalItemsCol key={`${item.id}`}>
              <Radio
                id={`${item.id}`}
                value={item.id}
                title={item.title}
                tooltip={item.tooltip}
                small
                checked={item.checked}
                onChange={() => {
                  this.props.changeOption(item.id, option.name);
                  this.props.validTechnicalView();
                }}
              />
            </TechnicalItemsCol>
          ))}
        </TechnicalItems>
      </TechnicalOption>
    );
  }

  render() {
    const {
      viewLoading,
      currency,
      translations,
      views: {
        technical: { ug, gas, paneExtras, uw, material, axis, windowExtras, priceRange },
      },
    } = this.props;

    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <Section>
            <Container fluid>
              <Row>
                <TechnicalCol image>
                  <TechnicalImg src={imageTechnical} />
                </TechnicalCol>
                <TechnicalCol>
                  <TechnicalGroup pane>
                    <TechnicalTitle primary left>
                      {translations.pane}
                    </TechnicalTitle>
                    <TechnicalOption>
                      <TechnicalLabel>
                        U<sub>G</sub>
                      </TechnicalLabel>
                      <TechnicalItems>
                        <TechnicalItemsCol select>
                          <Select
                            name="ug"
                            options={ug}
                            small
                            value={this.getCheckedId(ug)}
                            onChange={this.handleUgChange}
                          />
                        </TechnicalItemsCol>
                      </TechnicalItems>
                    </TechnicalOption>
                    {this.renderItemsChoose(gas)}
                    {this.renderItemsChoose(paneExtras)}
                  </TechnicalGroup>
                  <TechnicalGroup window>
                    <TechnicalTitle primary left>
                      {translations.window}
                    </TechnicalTitle>
                    <TechnicalOption>
                      <TechnicalLabel>
                        U<sub>w</sub>
                      </TechnicalLabel>
                      <TechnicalItems>
                        <TechnicalItemsCol select>
                          <Select
                            name="uw"
                            options={uw}
                            small
                            value={this.getCheckedId(uw)}
                            onChange={this.handleUwChange}
                          />
                        </TechnicalItemsCol>
                      </TechnicalItems>
                    </TechnicalOption>
                    {this.renderItemsChoose(material)}
                    {this.renderItemsChoose(axis)}
                    {this.renderItemsChoose(windowExtras)}
                    {priceRange && (
                      <TechnicalOption>
                        <TechnicalLabel>{priceRange.label}</TechnicalLabel>
                        <TechnicalItems>
                          <TechnicalItemsCol select>
                            <Range
                              min={priceRange.min}
                              max={priceRange.max}
                              step={priceRange.step}
                              defaultValue={[priceRange.value[0], priceRange.value[1]]}
                              onChange={value => this.handlePriceChange(value)}
                              onAfterChange={value => this.props.changePriceRange(value)}
                              allowCross={false}
                              trackStyle={[trackStyle, trackStyle]}
                              handleStyle={[handleStyle, handleStyle]}
                              railStyle={railStyle}
                            />
                            <PriceRangeLabels>
                              <PriceRangeLabel>
                                <span ref={this.rangeMin}>{priceRange.value[0]}</span> {currency}
                              </PriceRangeLabel>
                              <PriceRangeLabel>
                                <span ref={this.rangeMax}>{priceRange.value[1]}</span> {currency}
                              </PriceRangeLabel>
                            </PriceRangeLabels>
                          </TechnicalItemsCol>
                        </TechnicalItems>
                      </TechnicalOption>
                    )}
                  </TechnicalGroup>
                </TechnicalCol>
              </Row>
            </Container>
          </Section>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ viewLoading, views, currency, translations }) => ({
  viewLoading,
  views,
  currency,
  translations,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchView: pageId => dispatch(fetchViewAction(pageId)),
    changeOption: (id, type) => dispatch(changeOptionAction(id, type)),
    changeUg: id => dispatch(changeUgAction(id)),
    changeUw: id => dispatch(changeUwAction(id)),
    changePriceRange: value => dispatch(changePriceRangeAction(value)),
    validTechnicalView: () => dispatch(validTechnicalViewAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Technical);
