import React, { Component } from 'react';
import { connect } from 'react-redux';
import uniqBy from 'lodash/unionBy';
import orderBy from 'lodash/orderBy';

import {
  fetchView as fetchViewAction,
  changeAccessory as changeAccessoryAction,
  changeFilter as changeFilterAction,
} from '../../store/views/accessories/actions';

import { Section, Container, Row, LoaderView } from '../../layout';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
  AccordionTitle,
  AccessoriesCol,
  FiltersCol,
} from './Accessories.styled';
import AccessoriesItem from '../../components/AccessoriesItem/AccessoriesItem';
import Title from '../../components/Title/Title';
import Radio from '../../components/Radio/Radio';

class Accessories extends Component {
  componentDidMount() {
    const { match, accessoriesCopy } = this.props;
    const pageId = match.params.id;
    if (accessoriesCopy && accessoriesCopy.length === 0) {
      this.props.fetchView(pageId);
    }
  }

  getMergedAccessories(items) {
    const filters = this.getCheckedFiltersItems();
    let mergedItems = [];
    JSON.parse(JSON.stringify(items)).forEach(item => {
      mergedItems.push(...item.accessories);
    });

    if (filters && filters.length > 0) {
      mergedItems = mergedItems.filter(item => filters.includes(item.id));
    }

    mergedItems = uniqBy(mergedItems, 'id');

    const itemsWithRelated = [];

    mergedItems = mergedItems.filter(item => {
      if (item.colors && item.relatedAccessories) {
        itemsWithRelated.push(item);
        return false;
      }

      return true;
    });

    if (itemsWithRelated) {
      const mergedRelated = [];
      itemsWithRelated.forEach(item => {
        const relatedItemIndex = itemsWithRelated.findIndex(
          indexToRemove => indexToRemove.relatedAccessories === item.id,
        );

        if (relatedItemIndex !== -1) {
          const mainItem = JSON.parse(JSON.stringify(item));
          const itemToMerge = itemsWithRelated[relatedItemIndex];
          itemsWithRelated.splice(relatedItemIndex, 1);

          mainItem.colors.push(...itemToMerge.colors);
          mergedRelated.push(mainItem);
        }
      });

      mergedItems.push(...mergedRelated);
    }

    return orderBy(mergedItems, 'order');
  }

  getCheckedFiltersItems() {
    const filtersList = this.props.views.accessories.filters;
    const filters = [];

    if (filtersList) {
      const checkedFilters = filtersList.filter(filter => filter.checked);
      checkedFilters.forEach(el => {
        filters.push(...el.items);
      });
    }

    return filters;
  }

  render() {
    const { viewLoading, accessoriesCopy, views, translations } = this.props;
    const filtersList = views.accessories.filters;

    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <>
            {filtersList && filtersList.length > 0 && (
              <Section>
                <Container>
                  <Title as="h2" margin>
                    {translations.yourRequirements}
                  </Title>
                  <Row>
                    {filtersList.map(item => (
                      <FiltersCol key={item.name}>
                        <Radio
                          type="checkbox"
                          id={item.name}
                          value={item.name}
                          title={item.name}
                          checked={item.checked}
                          onChange={() => this.props.changeFilter(item.name)}
                        />
                      </FiltersCol>
                    ))}
                  </Row>
                </Container>
              </Section>
            )}
            {accessoriesCopy && (
              <Section>
                <Container>
                  <Title as="h2" margin>
                    {translations.addAccessoriesToWindow}
                  </Title>
                  <Accordion allowZeroExpanded preExpanded={[0]}>
                    {accessoriesCopy.map((size, index) => (
                      <AccordionItem key={size.name} uuid={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <AccordionTitle>
                              {`${size.name} (${size.dimension.width} x ${size.dimension.height})`}
                            </AccordionTitle>
                            <ButtonIcon as="span" className="accordion-button" icon="arrowDown" />
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Row>
                            {this.getMergedAccessories(size.windows).map(el => (
                              <AccessoriesCol key={`${size.name}${el.id}`}>
                                <AccessoriesItem
                                  id={`${size.name}${el.id}`}
                                  itemId={el.id}
                                  size={size.name}
                                  title={el.name}
                                  image={el.image}
                                  colors={el.colors}
                                  tooltip={el.description}
                                  checked={el.checked}
                                  onChangeFn={this.props.changeAccessory}
                                />
                              </AccessoriesCol>
                            ))}
                          </Row>
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </Container>
              </Section>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ viewLoading, views, accessoriesCopy, translations }) => ({
  viewLoading,
  views,
  accessoriesCopy,
  translations,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchView: pageId => dispatch(fetchViewAction(pageId)),
    changeAccessory: (size, id, color) => dispatch(changeAccessoryAction(size, id, color)),
    changeFilter: name => dispatch(changeFilterAction(name)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Accessories);
