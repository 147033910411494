export const clearResponse = ({ acf: resposne }) => {
  return {
    header: {
      title: resposne.main_title,
      description: resposne.subtitle,
    },
    headerNoResults: {
      title: resposne.header_text_title_summary,
      description: resposne.header_text_description_summary,
    },
    headerAccessories: {
      title: resposne.accesories_main_title,
      description: resposne.accessories_subtitle,
    },
  };
};
