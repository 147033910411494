import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchView as fetchViewAction,
  changeRoomType as changeRoomTypeAction,
  changeRequirement as changeRequirementAction,
  validBasicView as validBasicViewAction,
} from '../../store/views/basic/actions';
import { Section, Container, Row, LoaderView } from '../../layout';
import Title from '../../components/Title/Title';
import Radio from '../../components/Radio/Radio';
import { iconList } from '../../assets';
import { RoomTypeCol, RequirementsCol } from './Basic.styled';

class Basic extends Component {
  componentDidMount() {
    const { match, viewFetched, fetchView, validBasicView } = this.props;

    if (!viewFetched) {
      const pageId = match.params.id;
      fetchView(pageId);
    }

    validBasicView();
  }

  render() {
    const { viewLoading, roomTypes, requirements, translations } = this.props;

    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <>
            <Section>
              <Container>
                <Title as="h2" margin>
                  {translations.roomType}
                </Title>
                <Row center>
                  {roomTypes.map(item => (
                    <RoomTypeCol key={`room${item.id}`}>
                      <Radio
                        id={`room${item.id}`}
                        name="roomType"
                        value={item.id}
                        title={item.title}
                        image={item.image}
                        tooltip={item.tooltip}
                        checked={item.checked}
                        onChange={() => {
                          this.props.changeRoomType(item.id);
                          this.props.validBasicView();
                        }}
                      />
                    </RoomTypeCol>
                  ))}
                </Row>
              </Container>
            </Section>
            <Section>
              <Container>
                <Title as="h2" margin>
                  {translations.yourRequirements}
                </Title>
                <Row>
                  {requirements.map(item => (
                    <RequirementsCol key={`req${item.id}}`}>
                      <Radio
                        type="checkbox"
                        id={`req${item.id}}`}
                        name="requirements[]"
                        value={item.id}
                        title={item.title}
                        tooltip={item.tooltip}
                        icon={iconList.hasOwnProperty(item.icon) ? iconList[item.icon] : null}
                        checked={item.checked}
                        onChange={() => this.props.changeRequirement(item.id)}
                        large
                      />
                    </RequirementsCol>
                  ))}
                </Row>
              </Container>
            </Section>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  viewLoading,
  views: {
    basic: { fetched: viewFetched, roomTypes, requirements },
  },
  translations,
}) => ({
  viewLoading,
  viewFetched,
  roomTypes,
  requirements,
  translations,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchView: pageId => dispatch(fetchViewAction(pageId)),
    changeRoomType: id => dispatch(changeRoomTypeAction(id)),
    changeRequirement: id => dispatch(changeRequirementAction(id)),
    validBasicView: () => dispatch(validBasicViewAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Basic);
