import { appError, fetchViewStart, fetchViewSuccess } from '../store/app/actions';
import { API_PATHS, axiosInstance } from './api';

export const fetchView = (dispatch, pageId, viewType, responseFn) => {
  dispatch(fetchViewStart());

  axiosInstance
    .get(`${API_PATHS.pages}${pageId}`)
    .then(response => {
      dispatch(fetchViewSuccess(viewType, responseFn(response.data)));
    })
    .catch(() => {
      dispatch(appError());
    });
};

export const setCheckedById = (list, itemId) => {
  return list.map(item => {
    if (item.id === itemId) {
      item.checked = !item.checked;
    }
    return item;
  });
};

export const setCheckedOneById = (list, itemId) => {
  return list.map(item => {
    item.checked = item.id === itemId ? !item.checked : false;

    return item;
  });
};
