const reducerAddSize = state => {
  return {
    ...state,
    sizes: [
      ...state.sizes,
      {
        id: 'none',
        title: state.translations.selectFromList,
        count: 1,
      },
    ],
  };
};

const reducerChangeSize = (state, action) => {
  const sizes = state.sizes.map((item, index) => {
    if (index === action.payload.index) {
      return {
        ...action.payload.size,
        count: 1,
      };
    }
    return item;
  });
  return {
    ...state,
    sizes,
  };
};

const reducerChangeSizeCount = (state, action) => {
  const windowsCounts = state.sizes.map((item, index) => {
    if (index === action.payload.index) {
      return {
        ...item,
        count: +action.payload.count,
      };
    }
    return item;
  });

  return {
    ...state,
    sizes: windowsCounts,
  };
};

const reducerDeleteSize = (state, action) => {
  const windowsDelete = state.sizes.filter((item, index) => index !== action.payload.index);

  return {
    ...state,
    sizes: windowsDelete,
  };
};

export { reducerAddSize, reducerChangeSize, reducerChangeSizeCount, reducerDeleteSize };
