export const clearResponse = ({ acf: { room_type_box: roomTypes = [], requirements = [] } }) => {
  return {
    roomTypes: roomTypes.map(item => ({
      id: +item.box_system_value,
      title: item.box_title,
      image: item.box_image,
      tooltip: item.box_tooltip,
      checked: false,
    })),
    requirements: requirements.map(item => ({
      id: item.requirement_system_value.term_id,
      title: item.requirement_label,
      icon: item.requirement_icon,
      tooltip: item.tooltip_content,
      checked: false,
    })),
  };
};
