import React from 'react';
import {
  ProductThumbWrapper,
  ProductThumbImages,
  ProductThumbTitleBox,
  ProductThumbTitle,
  ProductThumbLink,
} from './ProductThumb.styled';

const PoductThumb = ({ image, title, url, }) => (
  <ProductThumbWrapper>
    <ProductThumbImages>
      <img src={image} alt="" />
    </ProductThumbImages>
    <ProductThumbTitleBox>
      <ProductThumbTitle>{title}</ProductThumbTitle>
    </ProductThumbTitleBox>
    {url && <ProductThumbLink href={url} target="_blank" />}
  </ProductThumbWrapper>
);

export default PoductThumb;
