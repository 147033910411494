export const clearResponse = ({ acf: { table_copy: info = [] } }, sizes) => {
  return {
    info: info.map(item => ({
      contentType: item.acf_fc_layout,
      content: item[item.acf_fc_layout],
    })),
    sizesList: sizes.map(item => ({
      id: item.title,
      title: item.title.toUpperCase(),
      field: item.field,
      dimension: {
        width: item.dimension.width,
        height: item.dimension.height,
      },
    })),
  };
};
