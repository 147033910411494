import React, {Component} from 'react';
import {connect} from 'react-redux';

import {fetchView as fetchViewAction} from '../../store/views/summary/actions';
import {
    changeStep as changeStepAction,
    goToAccessories as goToAccessoriesAction,
} from '../../store/app/actions';

import {Section, Container, LoaderView} from '../../layout';
import Title from '../../components/Title/Title';
import SummaryTable from '../../components/SummaryTable/SummaryTable';
import SummaryAccessoriesTable from '../../components/SummaryAccessoriesTable/SummaryAccessoriesTable';
import Button from '../../components/Button/Button';
import {SummaryButtons, SummaryButtonsCol} from './Summary.styled';
import {API_PATHS, axiosInstance} from '../../helpers/api';
import ProductSlider from '../../components/ProductSlider/ProductSlider';
import ModalPdf from '../../components/ModalPdf/ModalPdf';
import {getAccessoriesUniqIds} from '../../helpers/accessories';

class Summary extends Component {
    state = {
        download: false,
        showModal: false
    };

    downloadRef = React.createRef();

    componentDidMount() {
        const {match, fromAccessories, onlyAccessories} = this.props;
        if (!fromAccessories || onlyAccessories) {
            const pageId = match.params.id;
            this.props.fetchView(pageId);
        }
    }

    getAccessoryWindows = id => {
        const {accessories} = this.props;
        const windowsList = [];

        accessories.forEach(group => {
            group.windows.forEach(windowItem => {
                const accessory = windowItem.accessories.find(accessoryItem => accessoryItem.id === id);

                if (accessory && accessory.checked) {
                    let valid = true;
                    const dataObj = {
                        id: windowItem.windowId,
                    };

                    if (accessory.colors) {
                        const color = accessory.colors.find(colorItem => colorItem.checked);

                        if (color) {
                            dataObj.color = color.color_name;
                        } else {
                            valid = false;
                        }
                    }

                    if (valid) {
                        windowsList.push(dataObj);
                    }
                }
            });
        });
        return windowsList;
    };

    getAccessoriesData = () => {
        const {accessories} = this.props;
        if (accessories.length > 0) {
            const accessoriesListIds = getAccessoriesUniqIds(accessories);

            return accessoriesListIds.map(el => ({
                id: el,
                windows: this.getAccessoryWindows(el),
            }));
        }
        return null;
    };

    handleGeneratePdf = () => {
        const {views, windows, onlyAccessories} = this.props;
        const formData = new FormData();

        if (!onlyAccessories) {
            windows.forEach(item => {
                formData.append('windows[]', item);
            });

            views.flashing.flashings
                .filter(item => item.checked)
                .forEach(item => {
                    formData.append('roofings[]', item.title);
                });
        }

        const accessoriesData = this.getAccessoriesData();

        if (accessoriesData) {
            formData.append('accessories', JSON.stringify(accessoriesData));
        }

        this.setState({download: true});

        axiosInstance
            .post(API_PATHS.pdf, formData)
            .then(response => {
                // window.open(response.data.fileUrl);
                this.downloadRef.current.href = response.data.fileUrl;
                this.downloadRef.current.click();
                this.setState({download: false});
            })
            .catch(error => {
                this.setState({download: false});
            });
    };

    handleOpenModal = () => {
        this.setState({showModal: true});
    };

    handleCloseModal = () => {
        this.setState({showModal: false});
    };

    render() {
        const {viewLoading, windows, accessories, translations, onlyAccessories} = this.props;
        const accessoriesList = getAccessoriesUniqIds(accessories);

        return (
            <>
                {viewLoading ? (
                    <LoaderView/>
                ) : (
                    <>
                        {!onlyAccessories && (
                            <Section>
                                <Container>
                                    <Title as="h2" margin>
                                        {translations.windowsSummary}
                                    </Title>
                                    <SummaryTable/>
                                </Container>
                            </Section>
                        )}
                        {accessories && accessories.length > 0 && (
                            <Section>
                                <Container>
                                    <SummaryAccessoriesTable/>
                                </Container>
                            </Section>
                        )}
                        {windows && windows.length > 0 && (
                            <>
                                <ProductSlider/>
                                <Section>
                                    <Container>
                                        <SummaryButtons>
                                            {accessoriesList.length === 0 && (
                                                <SummaryButtonsCol>
                                                    <Button
                                                        icon="addCircle"
                                                        outline
                                                        large
                                                        onClick={() => this.props.goToAccessories()}
                                                    >
                                                        {translations.addAccessories}
                                                    </Button>
                                                </SummaryButtonsCol>
                                            )}
                                            <SummaryButtonsCol>
                                                <Button
                                                    icon="pdf"
                                                    large
                                                    disabled={this.state.download}
                                                    loading={this.state.download}
                                                    onClick={() => this.handleOpenModal()}
                                                >
                                                    {translations.generatePdf}
                                                </Button>
                                                <a
                                                    href=""
                                                    download
                                                    ref={this.downloadRef}
                                                    style={{display: 'none'}}
                                                />
                                            </SummaryButtonsCol>
                                        </SummaryButtons>
                                    </Container>
                                    <ModalPdf
                                        showModal={this.state.showModal}
                                        closeModalFn={this.handleCloseModal}
                                        sendFn={this.handleGeneratePdf}
                                        download={this.state.download}
                                    />
                                </Section>
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = ({
                             viewLoading,
                             appType,
                             steps,
                             views,
                             windows,
                             fromAccessories,
                             accessories,
                             translations,
                             onlyAccessories,
                             sizes
                         }) => ({
    viewLoading,
    appType,
    steps,
    views,
    windows,
    fromAccessories,
    accessories,
    translations,
    onlyAccessories,
    sizes
});

const mapDispatchToProps = dispatch => {
    return {
        fetchView: pageId => dispatch(fetchViewAction(pageId)),
        changeStep: id => dispatch(changeStepAction(id)),
        goToAccessories: () => dispatch(goToAccessoriesAction()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Summary);
