import axios from 'axios';
import { getLangCode } from './lang';

export const API_PATHS = {
  settings: '/configurator/v1/global-settings',
  pages: '/acf/v3/pages/',
  media: '/wp/v2/media/',
  sizes: '/configurator/v1/sizes-list',
  products: '/configurator/v1/products',
  flashing: '/wp/v2/installation-accessories',
  gas: '/wp/v2/gas',
  paneExtras: '/wp/v2/pane-extras',
  material: '/wp/v2/material',
  axis: '/wp/v2/axis',
  windowExtras: '/wp/v2/window-extras',
  pdf: '/configurator/v1/pdf',
  productsSlider: '/configurator/v1/products-slider',
  windowsWithSizes: '/configurator/v1/available-accesories-sizes',
  windowAccessory: '/configurator/v1/available-window-accessory',
  translation: '/configurator/v1/translate',
  form: '/contact-form-7/v1/contact-forms/:id/feedback',
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BASE,
  headers: {
    'icl-lang-code': getLangCode(),
  },
});
