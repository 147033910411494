export const clearResponse = ({ acf: { value_lines: ranges = [] }}) => {
  return {
    ranges: ranges.map(item => ({
      title: item.line_title,
      id: item.system_value,
      max: item.scale_range,
      min: 1,
      step: 1,
      value: 3,
    })),
  };
};
