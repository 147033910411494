import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentCol = styled.div`
  flex: 0 0 100%;
  margin-bottom: 16px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex: 50%;
  }
`;

const ContentTitle = styled.div`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const joinOption = option => {
  return option
    .filter(item => item.checked)
    .map(item => item.title)
    .join(', ');
};

const ContentTechnical = ({ data, currency, translations }) => {
  const { ug, uw, gas, paneExtras, material, axis, windowExtras, priceRange } = data;

  const ugFormat = joinOption(ug);
  const uwFormat = joinOption(uw);
  const gasFormat = joinOption(gas.items);
  const paneExtrasFormat = joinOption(paneExtras.items);
  const materialFormat = joinOption(material.items);
  const axisFormat = joinOption(axis.items);
  const windowExtrasFormat = joinOption(windowExtras.items);
  const priceFormat = `${priceRange.value[0]} ${currency} - ${priceRange.value[1]} ${currency}`;

  return (
    <>
      <Content>
        <ContentCol>
          <ContentTitle>{translations.pane}</ContentTitle>
          {ugFormat && (
            <p>
              <strong>
                U<sub>G</sub>:
              </strong>{' '}
              {ugFormat}
            </p>
          )}
          {gasFormat && (
            <p>
              <strong>{gas.label}:</strong> {gasFormat}
            </p>
          )}
          {paneExtrasFormat && (
            <p>
              <strong>{paneExtras.label}:</strong> {paneExtrasFormat}
            </p>
          )}
          {!ugFormat && !gasFormat && !paneExtrasFormat && <p>{translations.notSpecified}</p>}
        </ContentCol>
        <ContentCol>
          <ContentTitle>{translations.window}</ContentTitle>
          {uwFormat && (
            <p>
              <strong>
                U<sub>W</sub>:
              </strong>{' '}
              {uwFormat}
            </p>
          )}
          {materialFormat && (
            <p>
              <strong>{material.label}:</strong> {materialFormat}
            </p>
          )}
          {axisFormat && (
            <p>
              <strong>{axis.label}:</strong> {axisFormat}
            </p>
          )}
          {windowExtrasFormat && (
            <p>
              <strong>{windowExtras.label}:</strong> {windowExtrasFormat}
            </p>
          )}
          {!uwFormat && !materialFormat && !axisFormat && !windowExtrasFormat && <p>{translations.notSpecified}</p>}
        </ContentCol>
        <ContentCol>
          <ContentTitle>{translations.price}</ContentTitle>
          {priceFormat && (
            <p>
              <strong>{priceRange.label}:</strong> <span style={{textTransform: 'uppercase'}}>{priceFormat}</span>
            </p>
          )}
          {!priceFormat && <p>{translations.notSpecified}</p>}
        </ContentCol>
      </Content>
    </>
  );
};

const mapStateToProps = ({ currency }) => ({
  currency,
});

export default connect(mapStateToProps)(ContentTechnical);
