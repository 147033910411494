import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from '../Header/Header';
import HeaderDescription from '../HeaderDescription/HeaderDescription';
import Tabs from '../Tabs/Tabs';
import { changeStep as changeStepAction } from '../../store/app/actions';

class HeaderSteps extends Component {
  render() {
    const { appType, activeStepIndex, steps, changeStep } = this.props;

    const headerDescription = steps[appType][activeStepIndex].description;

    return (
      <Header>
        <Tabs
          items={steps[appType]}
          activeStepIndex={activeStepIndex}
          large
          handleClickTab={id => changeStep(id)}
        />
        {headerDescription && (
          <HeaderDescription dangerouslySetInnerHTML={{ __html: `${headerDescription}` }} />
        )}
      </Header>
    );
  }
}

HeaderSteps.propTypes = {
  appType: PropTypes.string.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  changeStep: PropTypes.func.isRequired,
};

const mapStateToProps = ({ appType, activeStepIndex, steps }) => ({
  appType,
  activeStepIndex,
  steps,
});

const mapDispatchToProps = dispatch => {
  return {
    changeStep: id => dispatch(changeStepAction(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderSteps);
