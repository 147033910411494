import { API_PATHS, axiosInstance } from '../../../helpers/api';
import {
  appError,
  fetchTranslationsSuccess,
  fetchViewStart,
  fetchViewSuccess,
} from '../../app/actions';
import { clearResponse } from './clearResponse';
import { fetchTranslations } from '../../../helpers/translation';

const fetchView = pageId => async dispatch => {
  dispatch(fetchViewStart());

  const pageData = await axiosInstance
    .get(`${API_PATHS.pages}${pageId}`)
    .then(response => {
      return clearResponse(response.data);
    })
    .catch(() => {
      dispatch(appError());
    });

  const translationsList = [
    { key: 'roomType', value: 'Room type' },
    { key: 'yourRequirements', value: 'Your requirements' },
  ];

  const translations = await fetchTranslations(translationsList);

  dispatch(fetchTranslationsSuccess(translations));
  dispatch(fetchViewSuccess('basic', pageData));
};

const validBasicView = () => ({
  type: 'VALID_BASIC_VIEW',
});

const changeRoomType = id => ({
  type: 'CHANGE_ROOM_TYPE',
  payload: {
    id,
  },
});

const changeRequirement = id => ({
  type: 'CHANGE_REQUIREMENT',
  payload: {
    id,
  },
});

export { fetchView, changeRoomType, changeRequirement, validBasicView };
